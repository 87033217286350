import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Topbar from 'components/Topbar/Topbar';
import Sidebar from 'components/Sidebar/Sidebar';
import SidebarAccountManager from 'components/Sidebar/SidebarAccountManager';
import AccountManagerList from 'pages/AccountManager/AMList';
import CreateAccountManager from 'pages/AccountManager/CreateAM';
import UpdateAccountManager from 'pages/AccountManager/UpdateAM';
import AuthorisedSchoolList from 'pages/AccountManager/AuthorisedSchoolList';
import { AccountManagerContainer } from './AM.styles';

const AccountManager = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Topbar />
      <Sidebar>
        <SidebarAccountManager />
      </Sidebar>
      <AccountManagerContainer>
        {pathname === '/account-manager' && <AccountManagerList />}
        <Switch>
          <Route
            exact
            path="/account-manager/list"
            component={AccountManagerList}
          />
          <Route
            exact
            path="/account-manager/create-account-manager"
            component={CreateAccountManager}
          />
          <Route
            exact
            path="/account-manager/account-manager/edit"
            component={UpdateAccountManager}
          />
          <Route
            exact
            path="/account-manager/account-manager/authorised-school-list/:id"
            component={AuthorisedSchoolList}
          />
        </Switch>
      </AccountManagerContainer>
    </>
  );
};

export default AccountManager;
