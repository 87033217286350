import React, { useState, useEffect } from 'react';
import Button from 'components/Shared/Button';
import { FormInput, OptionInput } from 'components/Shared/FormInput';
import InfoModal from 'components/Shared/InfoModal';
import {
  createSchool,
  updateSchool,
  getSchoolById,
  getExistingSchool,
} from 'services/schools';
import {
  countryList,
  AccessibilityList,
  customerTypeList,
  StatusList,
} from 'constants/index';
import isAccountManagerInList from 'helpers/checkAccountManager';
import { SchoolFormContainer } from './SchoolForm.styles';

const SchoolForm = ({
  type,
  CustomerId,
  ExistingSchool,
  accountManagers,
  openModal,
}) => {
  // form states & handlers
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [input, setInput] = useState({
    address: '',
    postalCode: '',
    contactperson: '',
    designation: '',
    contactnumber: '',
    email: '',
    website: '',
    accessibility: AccessibilityList[0].id,
    type: customerTypeList[0].id,
    status: StatusList[0].id,
    countryId: countryList[0].id,
    schoolid: '',
    schoolName: '',
    studentPopulation: '',
    managerId: '',
    School_Id: '',
    CustomerId: '',
  });

  // bind data for edit school
  const bindData = (val) => {
    const tempState = { ...input };
    if (val.CustomerId) tempState.CustomerId = val.CustomerId;
    if (val.Address) tempState.address = val.Address;
    if (val.PostalCode) tempState.postalCode = val.PostalCode;
    if (val.Contactperson) tempState.contactperson = val.Contactperson;
    if (val.Designation) tempState.designation = val.Designation;
    if (val.Contactnumber) tempState.contactnumber = val.Contactnumber;
    if (val.Email) tempState.email = val.Email;
    if (val.Website) tempState.website = val.Website;
    if (val.Accessibility) tempState.accessibility = val.Accessibility;
    if (val.Type) tempState.type = val.Type;
    if (val.Status) tempState.status = val.Status;
    if (type === 'existing-school' ? val[0].CountryID : val.CountryId)
      tempState.countryId =
        type === 'existing-school' ? val[0].CountryID : val.CountryId;
    if (val.SchoolId) tempState.schoolid = val.SchoolId;
    if (type === 'existing-school' ? val[0].Name : val.SchoolName)
      tempState.schoolName =
        type === 'existing-school' ? val[0].Name : val.SchoolName;
    if (val.StudentPopulation)
      tempState.studentPopulation = val.StudentPopulation;
    if (val.ManagerId) tempState.managerId = val.ManagerId;
    if (type === 'existing-school' ? val[0].ID : val.School_Id)
      tempState.School_Id =
        type === 'existing-school' ? val[0].ID : val.School_Id;
    setInput(tempState);
  };

  useEffect(() => {
    if (type === 'update') {
      getSchoolById(CustomerId).then((res) => {
        bindData(res.Result);
      });
    }
    if (type === 'existing-school') {
      getExistingSchool(ExistingSchool).then((res) => {
        bindData(res.Result);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, CustomerId, ExistingSchool]);

  const changeState = (val, inputType) => {
    const tempState = { ...input };
    tempState[inputType] = val;
    setInput(tempState);
  };

  // create school
  const firstAccountManagerId = accountManagers && accountManagers[0].id;
  const createSchoolForm = () => {
    const formData = {
      address: input.address,
      postalCode: input.postalCode,
      contactperson: input.contactperson,
      contactnumber: input.contactnumber,
      email: input.email,
      accessibility: parseInt(input.accessibility, 10),
      type: parseInt(input.type, 10),
      status: parseInt(input.status, 10),
      countryId: parseInt(input.countryId, 10),
      schoolid: input.schoolid,
      schoolName: input.schoolName,
      studentPopulation: input.studentPopulation,
      managerId:
        input.managerId === ''
          ? firstAccountManagerId
          : parseInt(input.managerId, 10),
    };
    if (input.School_Id) formData.School_Id = input.School_Id;
    if (input.designation) formData.designation = input.designation;
    if (input.website) formData.website = input.website;
    return formData;
  };

  // submit function
  const submitForm = async (e) => {
    e.preventDefault();
    const id = CustomerId;
    const rawBody = createSchoolForm();
    const api = type === 'update' ? updateSchool : createSchool;
    await api(rawBody, id).then((res) => {
      if (res.IsSuccessful) {
        openModal();
      } else {
        setShowInfoModal(true);
      }
    });
  };
  return (
    <SchoolFormContainer>
      <InfoModal
        msg="Please fill required field(s)"
        show={showInfoModal}
        variant="danger"
        hide={() => {
          setShowInfoModal(false);
        }}
      />
      <form id="school-form" onSubmit={submitForm}>
        <div className="row">
          <OptionInput
            position="col"
            label="Country"
            options={countryList}
            bind="countryId"
            name="countryId"
            valueKey="id"
            optionKey="name"
            value={input.countryId}
            onChange={changeState}
          />
        </div>
        <div className="form-row">
          <FormInput
            position="col"
            name="schoolid"
            label="School ID"
            type="text"
            value={input.schoolid}
            onChange={changeState}
            required
          />
          <FormInput
            position="col"
            name="schoolName"
            label="School Name"
            type="text"
            value={input.schoolName}
            onChange={changeState}
            required
          />
        </div>
        <div className="form-row">
          <OptionInput
            position="col"
            label="Customer Type"
            options={customerTypeList}
            name="type"
            optionKey="name"
            valueKey="id"
            bind="type"
            value={input.type}
            onChange={changeState}
            required
            defaultValue={input.type}
          />
          <OptionInput
            position="col"
            label="Status"
            options={StatusList}
            optionKey="name"
            valueKey="id"
            name="status"
            bind="status"
            value={input.status}
            onChange={changeState}
            required
            disabled={type === 'update'}
          />
          <FormInput
            position="col"
            name="studentPopulation"
            label="Student Population"
            type="number"
            value={input.studentPopulation}
            onChange={changeState}
            required
          />
        </div>
        <OptionInput
          label="Accessibility"
          options={AccessibilityList}
          optionKey="name"
          valueKey="id"
          name="accessibility"
          bind="accessibility"
          value={input.accessibility}
          onChange={changeState}
          required
        />
        <div className="form-row">
          <FormInput
            position="col"
            name="contactperson"
            label="Contact Person"
            type="text"
            value={input.contactperson}
            onChange={changeState}
            required
          />
          <FormInput
            position="col"
            name="contactnumber"
            label="Contact Number"
            type="text"
            value={input.contactnumber}
            onChange={changeState}
            required
          />
          <FormInput
            position="col"
            name="email"
            label="Email"
            type="email"
            value={input.email}
            onChange={changeState}
            required
          />
        </div>
        <FormInput
          label="Designation"
          name="designation"
          type="text"
          value={input.designation}
          onChange={changeState}
        />
        <div className="row">
          <FormInput
            position="col"
            label="Address"
            name="address"
            type="text"
            value={input.address}
            onChange={changeState}
            required
          />
          <FormInput
            position="col"
            label="Postal Code"
            name="postalCode"
            type="text"
            value={input.postalCode}
            onChange={changeState}
            required
          />
          <FormInput
            position="col"
            label="School Website"
            name="website"
            type="text"
            value={input.website}
            onChange={changeState}
          />
        </div>
        <OptionInput
          label="Account Manager"
          options={accountManagers}
          name="managerId"
          optionKey="name"
          bind="managerId"
          valueKey="id"
          required
          onChange={changeState}
          value={input.managerId}
        >
          {input.managerId !== '' &&
            accountManagers &&
            isAccountManagerInList(input.managerId, accountManagers) ===
              false && <option value={input.managerId}>Account Manager</option>}
        </OptionInput>
        <div className="btn">
          <Button variant="primary-btn" type="submit">
            {type === 'update' ? 'Save' : 'Create School'}
          </Button>
        </div>
      </form>
    </SchoolFormContainer>
  );
};

export default SchoolForm;
