import { logout } from 'services/login';
import { isNil } from 'ramda';

const refreshToken = () => {
  const refreshToken = localStorage.getItem('refresh_token');
  // console.log('old refresh token', refreshToken);
  if (isNil(refreshToken)) {
    logout();
  }
  const urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', 'refresh_token');
  urlencoded.append('client_id', process.env.REACT_APP_CLIENT_ID);
  urlencoded.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
  urlencoded.append('refresh_token', refreshToken);
  return fetch(`${process.env.REACT_APP_API_DOMAIN}/token`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: urlencoded,
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!isNil(result.error)) {
        logout();
      } else {
        return result;
      }
    })
    .catch((err) => {
      logout();
      throw new Error(err?.message ?? 'Refresh token failed'); // eslint-disable-line
    });
};

const refreshFetchWrapper = async (request, params) => {
  try {
    const refreshTokenResult = await refreshToken();
    localStorage.setItem('access_token', refreshTokenResult.access_token);
    localStorage.setItem('refresh_token', refreshTokenResult.refresh_token);
    // console.log('new refresh token', refreshTokenResult);
    return request(params);
  } catch (err) {
    throw new Error(err?.message ?? 'Refresh token failed'); // eslint-disable-line
  }
};

export default refreshFetchWrapper;
