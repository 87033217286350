import React from 'react';
import { AuthorisedListTableContainer } from './AuthorisedListTable.styles';

const AuthorisedListTable = ({ data }) => {
  return (
    <AuthorisedListTableContainer>
      <table className="table-responsive-md table table-bordered ">
        <thead>
          <tr>
            <th scope="col" className="no">
              No
            </th>
            <th scope="col" className="country">
              Country
            </th>
            <th scope="col" className="ID">
              School ID
            </th>
            <th scope="col" className="name">
              School Name
            </th>
            <th scope="col" className="population">
              Student Population
            </th>
            <th scope="col" className="accessibility">
              Accessibility
            </th>
            <th scope="col" className="customer-status">
              Customer Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={item.schoolId}>
                <td>{index + 1}</td>
                <td>{item.countryName === null ? '-' : item.countryName}</td>
                <td>{item.schoolId === null ? '-' : item.schoolId}</td>
                <td>{item.schoolName === null ? '-' : item.schoolName}</td>
                <td>
                  {item.studentPopulation === null
                    ? '-'
                    : item.studentPopulation}
                </td>
                <td>
                  {item.accessibility === null ? '-' : item.accessibility}
                </td>
                <td>{item.status === null ? '-' : item.status}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </AuthorisedListTableContainer>
  );
};

export default AuthorisedListTable;
