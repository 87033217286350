import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import DropdownMenu from 'components/Shared/DropdownMenu';
import SchoolListTable from 'components/School/SchoolListTable';
import PopupModal from 'components/Shared/PopupModal';
import DeleteModal from 'components/School/DeleteModal';
import SearchResult from 'components/School/SearchResult';
import Pagination from 'components/Shared/Pagination';
import { getSchools, deleteSchool } from 'services/schools';
import Spinner from 'components/Shared/Spinner';
import { sortCountry, compareValues } from 'helpers/parseSchoolList';
import refreshFetchWrapper from 'services/refreshToken';
import SchoolListContainer from './SchoolList.styles';

const SchoolList = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    refreshFetchWrapper(getSchools).then((res) => {
      setIsLoading(false);
      setData(res.Result.List);
    });
  }, []);

  // pagination
  const maxDataPages = Math.ceil(data && data.length / 20);

  // display max number of data
  const perPage = 20;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dataSlice = data && data.slice(offset, offset + perPage);

  // Callback for page change
  const onPageChange = (number) => {
    const newOffset = (number - 1) * perPage;
    setCurrentPage(number);
    setOffset(newOffset);
  };

  // delete function + modal state & handlers
  const [deletedId, setDeletedId] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const openDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteSchoolData = () => {
    deleteSchool(deletedId).then((res) => {
      const index = data.findIndex((item) => item.CustomerId === deletedId);
      const tempData = [...data];
      tempData.splice(index, 1);
      setData(tempData);
      hideDeleteModal();
    });
  };
  // search state
  const [searchTerm, setSearchTerm] = useState('');

  // filter state
  const [filterCountry, setFilterCountry] = useState('');
  const sortCountryData = data
    ? sortCountry(data).sort(compareValues('country', 'asc'))
    : [];
  const countryData =
    sortCountryData && sortCountryData.map((country) => country.country);

  return (
    <SchoolListContainer>
      <PopupModal show={showDeleteModal} hide={hideDeleteModal}>
        <DeleteModal
          hide={hideDeleteModal}
          type="school"
          onClick={deleteSchoolData}
        />
      </PopupModal>
      <div className="top-section">
        <div>School List</div>
        <div>
          <input
            type="search"
            id="search"
            className="form-control search-bar"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
      </div>
      {searchTerm.length >= 3 ? (
        <SearchResult searchTerm={searchTerm} />
      ) : (
        <>
          <div className="filter-section">
            <p>Filter by Country :</p>
            <DropdownMenu
              className="dropdown"
              selectedValue={filterCountry === '' ? 'All' : filterCountry}
              valueKey={null}
              values={countryData}
              setValue={setFilterCountry}
              width="160px"
            >
              <Dropdown.Item
                onClick={(event) => {
                  event.preventDefault();
                  setFilterCountry('');
                }}
              >
                All
              </Dropdown.Item>
            </DropdownMenu>
          </div>
          {isLoading && <Spinner />}
          {!isLoading && (
            <>
              <SchoolListTable
                key={data && data.CustomerId}
                data={dataSlice}
                openModal={openDeleteModal}
                filterCountry={filterCountry}
                page={currentPage}
              />
              <Pagination
                maxPage={maxDataPages}
                onPageChange={onPageChange}
                onSearchPageChange={onPageChange}
                page={currentPage}
                searchPage={currentPage}
              />
            </>
          )}
        </>
      )}
    </SchoolListContainer>
  );
};

export default SchoolList;
