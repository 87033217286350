import React from 'react';
import { MatchedResultSpan } from './MatchedResult.styles';

export default function MatchedResult({ result, searchTerm }) {
  const lowercaseResult = result.toLowerCase();
  const lowercaseSearchTerm = searchTerm.toLowerCase();
  const stringArray = lowercaseResult.split(lowercaseSearchTerm);
  const output = [];
  let originalStringIndex = 0;
  for (let i = 0; i < stringArray.length; i++) {
    if (i > 0) {
      output.push(
        result.slice(
          originalStringIndex,
          originalStringIndex + searchTerm.length
        )
      );
      originalStringIndex += searchTerm.length;
    }
    output.push(
      result.slice(
        originalStringIndex,
        originalStringIndex + stringArray[i].length
      )
    );
    originalStringIndex += stringArray[i].length;
  }
  return (
    <span>
      {output.map((text, index) => {
        if (text.toLowerCase() === searchTerm.toLowerCase()) {
          return (
            <MatchedResultSpan>
              <span
                key={`text-${index}`}
                className="result-highlighted-match"
              >{`${text}`}</span>
            </MatchedResultSpan>
          );
        }
        return <>{`${text}`}</>;
      })}
    </span>
  );
}
