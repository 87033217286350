// Checks if current account manager of contract is in list from api
const isAccountManagerInList = (currentAccountManagerID, accountManagers) => {
  const currentAccountManagerIndex = accountManagers.findIndex(
    (am) => am.id === currentAccountManagerID
  );
  if (currentAccountManagerIndex !== -1) {
    return true;
  }
  return false;
};

export default isAccountManagerInList;
