import styled from 'styled-components';

export const LoginSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
  background: #ffff;
  width: 70%;
  font-family: Linotte Regular;
  .logo {
    display: flex;
    align-items: center;
    color: #f25008;
    font-size: 18px;
    font-family: Muli;
    font-weight: bold;
    p {
      margin: 9px 7px 0px 12px;
      white-space: nowrap;
    }
  }
  .signup-container {
    display: flex;
    margin-top: 10px;
    p {
      width: 320px;
      font-size: 16px;
      font-family: Linotte Light;
      margin: 0px;
    }
    a {
      margin-top: 5px;
      text-align: center;
      border: 1px solid #ff5b00;
      border-radius: 5px;
      color: #ff5b00;
      height: 30px;
      width: 80px;
    }
  }
  footer {
    color: #9a9a9f;
    font-size: 16px;
    font-family: Linotte Light;
    position: fixed;
    bottom: 50px;
  }
`;
