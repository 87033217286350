// Helpers for parsing form input values into JSON format for API endpoints
export const parseCreateContractJSON = (data) => {
  const formData = { ...data };
  // Convert strings to correct value types
  const fieldsToConvertToInteger = [
    'AcademicYear',
    'AccountManagerId',
    'Currency',
    'PlanId',
    'TransitionPeriod',
  ];
  const fieldsToConvertToFloat = ['TotalValue'];
  for (const prop in formData) {
    if (fieldsToConvertToInteger.includes(prop)) {
      formData[prop] = parseInt(formData[prop]);
    } else if (fieldsToConvertToFloat.includes(prop)) {
      formData[prop] = parseFloat(formData[prop]);
    }
  }
  // Construct contract line items from properties starting with acctQuota-
  const contractFields = Object.keys(formData).filter((prop) =>
    prop.startsWith('acctQuota-')
  );
  formData.ContractDetails = [];
  for (const prop of contractFields) {
    const accountQuota = parseInt(formData[prop]);
    if (accountQuota > 0) {
      const delimiterIndex = prop.indexOf('-');
      const accountType = parseInt(prop.slice(delimiterIndex + 1));
      formData.ContractDetails.push({
        AccountType: accountType,
        AccountsPurchased: accountQuota,
        AccountsConsumed: 0,
        ItemType: 'Account',
      });
    }
    // Delete key
    delete formData[prop];
  }
  // Check if contract details is empty
  if (formData.ContractDetails.length === 0) {
    console.log('Error: no contract details');
  }
  // Update PaymentStatus value
  if (formData.PaymentStatus === true) {
    formData.PaymentStatus = 1;
  } else {
    formData.PaymentStatus = 2;
  }
  return formData;
};

export const parseUpdateContractJSON = (data, currentContract) => {
  // TODO: Add contractID
  const formData = {
    ContractId: data.ContractId,
    StartDate: data.StartDate,
    EndDate: data.EndDate,
    AcademicYearStartDate: data.AcademicYearStartDate,
    AcademicYearEndDate: data.AcademicYearEndDate,
    ContractDetails: [],
  };
  // Add in existing line items in existing contract
  const contractFields = Object.keys(data).filter((prop) =>
    prop.startsWith('acctQuota-')
  );
  for (const prop of contractFields) {
    const accountQuota = parseInt(data[prop]);
    if (accountQuota > 0) {
      const delimiterIndex = prop.indexOf('-');
      const accountType = parseInt(prop.slice(delimiterIndex + 1));
      // Check if accountType exists in current contract
      const contractLineItemIndex = currentContract.ContractDetails.findIndex(
        (lineItem) =>
          lineItem.AccountType === accountType &&
          lineItem.ItemType === 'Account'
      );
      if (contractLineItemIndex !== -1) {
        formData.ContractDetails.push({
          Contract_Item_Id:
            currentContract.ContractDetails[contractLineItemIndex]
              .Contract_Item_Id,
          AccountType: accountType,
          AccountsPurchased: accountQuota,
          AccountsConsumed:
            currentContract.ContractDetails[contractLineItemIndex]
              .AccountsConsumed,
          AccountsRemaining:
            currentContract.ContractDetails[contractLineItemIndex]
              .AccountsRemaining,
          ItemType: 'Account',
        });
      } else {
        formData.ContractDetails.push({
          AccountType: accountType,
          AccountsPurchased: accountQuota,
          AccountsConsumed: 0,
          ItemType: 'Account',
        });
      }
    }
  }
  return formData;
};
