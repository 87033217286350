import { isNil } from 'ramda';
import { history } from '../Routes';

export const login = (username, password) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', 'password');
  urlencoded.append('username', username);
  urlencoded.append('password', password);
  urlencoded.append('client_id', process.env.REACT_APP_CLIENT_ID);
  urlencoded.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
  return fetch(`${process.env.REACT_APP_API_DOMAIN}/token`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: urlencoded,
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!isNil(result.error)) {
        throw new Error(result.error_description);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Login failed'); // eslint-disable-line
    });
};

export const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  history.push('/login');
};
