import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import EmptyResult from 'assets/img/empty-search-results.svg';
import { SelectedSchoolDispatch } from 'pages/School/School';
import { ContractMenu, PopoverMenu } from '../Shared/ActionButtons';
import { SchoolListTableContainer } from './SchoolListTable.styles';

const SchoolListTable = ({ data, openModal, filterCountry, page }) => {
  const history = useHistory();
  const dispatch = useContext(SelectedSchoolDispatch);
  const filteredData = filterCountry
    ? data.filter((item) => item.CountryName === filterCountry)
    : data;

  const schoolData =
    filteredData &&
    filteredData.map((item, index) => (
      <tr key={index}>
        <td>{page > 1 ? page * 10 + (index + 1) : index + 1}</td>
        <td>{item.CountryName === null ? '-' : item.CountryName}</td>
        <td>{item.SchoolName === null ? '-' : item.SchoolName}</td>
        <td>{item.Contactperson === null ? '-' : item.Contactperson}</td>
        <td>{item.Designation === null ? '-' : item.Designation}</td>
        <td>{item.Contactnumber === null ? '-' : item.Contactnumber}</td>
        <td>
          <ContractMenu
            type="school"
            customerID={item.CustomerId}
            data={item}
            onClick={() =>
              history.push(`/school/school-list/details/${item.CustomerId}`)
            }
          />
        </td>
        <td>
          <PopoverMenu
            openModal={openModal}
            deleteId={item.CustomerId}
            onClick={() => {
              dispatch({
                type: 'SET_SELECTED_SCHOOL',
                data: item,
              });
              history.push(
                `/school/school-list/details/edit/${item.CustomerId}`
              );
            }}
          />
        </td>
      </tr>
    ));

  return (
    <SchoolListTableContainer>
      {schoolData && schoolData.length === 0 ? (
        <div className="empty-result">
          <img src={EmptyResult} alt="No results found" />
        </div>
      ) : (
        <table className="table-responsive-md table table-bordered ">
          <thead>
            <tr>
              <th scope="col" className="no">
                No
              </th>
              <th scope="col" className="country">
                Country
              </th>
              <th scope="col" className="school-name">
                School Name
              </th>
              <th scope="col" className="contact-person">
                Contact Person
              </th>
              <th scope="col" className="designation">
                Designation
              </th>
              <th scope="col" className="contact-number">
                Contact Number
              </th>
              <th scope="col" className="contracts">
                Contracts
              </th>
              <th scope="col" className="action" />
            </tr>
          </thead>
          <tbody>{schoolData}</tbody>
        </table>
      )}
    </SchoolListTableContainer>
  );
};

export default SchoolListTable;
