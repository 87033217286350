import React from 'react';
import NavItem from './NavItem';
import navs from './navs.json';
import { NavbarCollapseContainer } from './NavbarCollapse.styles';

const NavbarCollapse = () => {
  return (
    <NavbarCollapseContainer>
      <ul className="navbar-nav mx-lg-auto">
        {navs.map((nav, index) => {
          return (
            <NavItem key={String(index)} to={nav.to}>
              <span>{nav.name}</span>
            </NavItem>
          );
        })}
      </ul>
    </NavbarCollapseContainer>
  );
};

export default NavbarCollapse;
