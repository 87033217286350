import React from 'react';
import KoobitsLogo from 'assets/img/Login/login-koobits-logo.svg';
import LoginForm from './LoginForm/LoginForm';
import { LoginSectionContainer } from './LoginSection.styles';

const LoginSection = () => {
  return (
    <LoginSectionContainer>
      <div className="logo">
        <img src={KoobitsLogo} alt="logo" />
        <p>Sales Admin</p>
      </div>
      <LoginForm />

      <footer>KooBits Learning Pte Ltd</footer>
    </LoginSectionContainer>
  );
};

export default LoginSection;
