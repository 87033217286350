import styled from 'styled-components';

export const SchoolDetailsContainer = styled.div`
  .error {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
  }
  .top-bar {
    display: flex;
    justify-content: center;
    padding: 10px 50px;
    background: white;
    font-weight: bold;
    font-size: 18px;
  }
  .details {
    padding: 30px 50px 0;
    display: flex;
    justify-content: space-between;
    .action-buttons {
      display: flex;
      flex-direction: row;
      align-items: end;
      button {
        margin-right: 25px;
        padding: 0px 25px;
        height: 40px;
        font-size: 0.875rem;
        font-weight: 600;
        color: #727272;
        &:hover {
          color: #ff5b00;
        }
      }
    }
  }
  .title {
    background-color: #ffcc68;
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .contracts {
    padding: 40px 50px 0;
    .contract-title {
      display: flex;
      p {
        margin-bottom: 0px;
        margin-right: 10px;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
`;

export const DetailItemContainer = styled.div`
  p {
    margin-bottom: 4px;
    font-size: 14px;
  }
  h5 {
    font-weight: bold;
    font-size: 18px;
  }
`;
