import styled from 'styled-components';

export const TopbarContainer = styled.div`
  .top-bar {
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 20px 0px;
  }
  .back-nav {
    position: absolute;
    left: 230px;
    top: 77px;
    cursor: pointer;
    &:hover {
      color: #f25008;
    }
  }
  .title {
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
  }
  .primary-btn {
    position: absolute;
    right: 50px;
    top: 73px;
  }
`;
