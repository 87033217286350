import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Login from 'pages/Login/Login';
import School from 'pages/School/School';
import AccountManager from 'pages/AccountManager/AM';
import AuthenticatedRoute from 'components/Shared/AuthenticatedRoute';

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_SUBDOMAIN,
});

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <AuthenticatedRoute exact path="/" component={School} />
        <Route exact path="/login" component={Login} />
        <AuthenticatedRoute path="/school" component={School} />
        <AuthenticatedRoute
          path="/account-manager"
          component={AccountManager}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
