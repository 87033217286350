import React from 'react';

export default function PopoverMenuIcon({ icon, fill }) {
  switch (icon) {
    case 'edit':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.336"
          height="27.192"
          viewBox="0 0 21.336 27.192"
        >
          <path
            d="M126.137,60.308a2.193,2.193,0,0,0-2.235-2.15l-1.569-.031h-.05A2.2,2.2,0,0,0,120,60.186l-.045,1.884h0l-.448,19.237a.714.714,0,0,0,.115.4.678.678,0,0,0,.076.343l.948,1.835,1.116,2.16a.8.8,0,0,0,.7.413h.014a.8.8,0,0,0,.7-.387l.277-.483.761-1.324h0l.112-.2.059-.1.45-.785h0l.587-1.022,0,0a.685.685,0,0,0,.09-.333.731.731,0,0,0,.131-.4l.445-19.234h0Zm-3.855-1.089h.025l1.569.031a1.056,1.056,0,0,1,1.077,1.035l-.02.792-2.515-.052L121.166,61l.02-.792A1.06,1.06,0,0,1,122.282,59.219Zm.61,25.018-.394.684-.361-.7.2,0Zm-.99-1.112-.336-.008-.52-1,3.032.059-.565.983Zm3.007-20.956-.439,18.92-2.822-.057-.945-.018.439-18.92,1.27.026Z"
            transform="matrix(0.829, 0.559, -0.559, 0.829, -50.733, -115.019)"
            fill={fill}
          />
        </svg>
      );
    case 'delete':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.927"
          height="23.195"
          viewBox="0 0 19.927 23.195"
        >
          <path
            d="M5487.611,1878.909H5468.58a.45.45,0,0,1,0-.9h19.031a.45.45,0,0,1,0,.9Z"
            transform="translate(-5468.132 -1873.564)"
            fill={fill}
          />
          <path
            d="M5509.876,1896.759H5500a2.949,2.949,0,0,1-2.947-2.946V1878.46a.45.45,0,0,1,.45-.449h14.867a.449.449,0,0,1,.451.449v15.354A2.95,2.95,0,0,1,5509.876,1896.759Zm-11.922-17.85v14.9a2.049,2.049,0,0,0,2.047,2.047h9.875a2.048,2.048,0,0,0,2.045-2.047v-14.9Z"
            transform="translate(-5494.975 -1873.564)"
            fill={fill}
          />
          <path
            d="M5548.328,1954.213a.45.45,0,0,1-.45-.45V1944.4a.449.449,0,1,1,.9,0v9.362A.449.449,0,0,1,5548.328,1954.213Z"
            transform="translate(-5542.142 -1934.762)"
            fill={fill}
          />
          <path
            d="M5600.82,1954.213a.451.451,0,0,1-.451-.45V1944.4a.45.45,0,0,1,.9,0v9.362A.449.449,0,0,1,5600.82,1954.213Z"
            transform="translate(-5590.856 -1934.762)"
            fill={fill}
          />
          <path
            d="M5653.313,1954.213a.449.449,0,0,1-.449-.45V1944.4a.449.449,0,0,1,.9,0v9.362A.45.45,0,0,1,5653.313,1954.213Z"
            transform="translate(-5639.575 -1934.762)"
            fill={fill}
          />
          <path
            d="M5571.507,1821.543h-4.932a.45.45,0,0,1-.45-.449v-1.981a2.915,2.915,0,1,1,5.83,0v1.981A.449.449,0,0,1,5571.507,1821.543Zm-4.482-.9h4.031v-1.532a2.016,2.016,0,1,0-4.031,0Z"
            transform="translate(-5559.077 -1816.197)"
            fill={fill}
          />
        </svg>
      );
    default:
      return null;
  }
}
