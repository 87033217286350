import styled from 'styled-components';

export const AccountManagerContainer = styled.div`
  padding-left: 195px;
  padding-top: 51px;
  .right-btn {
    text-align: right;
  }
  @media (max-width: 971px) {
    padding-left: 65px;
  }
`;
