import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Shared/Button';
import { PopoverMenuItem } from 'components/Shared/ActionButtons';
import PopupModal from 'components/Shared/PopupModal';
import DeleteModal from 'components/School/DeleteModal';
import ContractTable from 'components/School/SchoolDetails/ContractTable';
import { getSchoolById, deleteSchool } from 'services/schools';
import { deleteContract } from 'services/contracts';
import Spinner from 'components/Shared/Spinner';
import { AccessibilityType } from 'constants/index';
import refreshFetchWrapper from 'services/refreshToken';
import InfoModal from 'components/Shared/InfoModal';
import {
  SchoolDetailsContainer,
  DetailItemContainer,
} from './SchoolDetails.styles';
import { SelectedSchoolDispatch } from './School';

const DetailItem = ({ title, data }) => {
  return (
    <DetailItemContainer>
      <p className="item-title">{title}</p>
      <h5 className="item-data">{data}</h5>
    </DetailItemContainer>
  );
};

const DetailTitle = ({ detail }) => {
  return <div className="title">{detail}</div>;
};

const SchoolDetails = ({ match }) => {
  const dispatch = useContext(SelectedSchoolDispatch);
  const schoolID = parseInt(match.params.id, 10);
  const history = useHistory();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    refreshFetchWrapper(getSchoolById, schoolID)
      .then((res) => {
        setIsLoading(false);
        setData(res.Result);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  }, [match.params.id, schoolID]);

  // modal state & handlers
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedContractID, setSelectedContractID] = useState(null);
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const [showDeleteContractModal, setShowDeleteContractModal] = useState(false);
  const openDeleteContractModal = (contractID) => {
    setSelectedContractID(contractID);
    setShowDeleteContractModal(true);
  };
  const hideDeleteContractModal = () => {
    setShowDeleteContractModal(false);
  };
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalMessage, setInfoModalMessage] = useState(null);

  return (
    <SchoolDetailsContainer>
      <InfoModal
        msg={infoModalMessage}
        show={showInfoModal}
        variant="danger"
        hide={() => {
          setShowInfoModal(false);
        }}
      />
      <PopupModal show={showDeleteModal} hide={hideDeleteModal}>
        <DeleteModal
          type="school"
          hide={hideDeleteModal}
          onClick={() => {
            deleteSchool(data && data.CustomerId);
            hideDeleteModal();
            history.push('/school/school-list');
          }}
        />
      </PopupModal>
      <PopupModal show={showDeleteContractModal} hide={hideDeleteContractModal}>
        <DeleteModal
          type="contract"
          hide={hideDeleteContractModal}
          onClick={async () => {
            try {
              const result = await deleteContract(selectedContractID);
              if (result === true) {
                hideDeleteContractModal();
                setIsLoading(true);
                getSchoolById(schoolID).then((res) => {
                  setIsLoading(false);
                  setData(res.Result);
                });
              }
            } catch (err) {
              hideDeleteContractModal();
              setInfoModalMessage(err.message);
              setShowInfoModal(true);
            }
          }}
        />
      </PopupModal>
      {isLoading && <Spinner />}
      {!isLoading && error === null && (
        <>
          <div className="top-bar">{data && data.SchoolName}</div>
          <div className="details">
            <div className="school">
              <DetailTitle detail="School Details" />
              <DetailItem title="Koobits ID" data={data && data.SchoolId} />
              <DetailItem title="School Name" data={data && data.SchoolName} />
              <DetailItem title="Country" data={data && data.CountryName} />
              <DetailItem
                title="Address"
                data={data && data.Address === '' ? '-' : data && data.Address}
              />
              <DetailItem title="Website" data={data && data.Website} />
              <DetailItem
                title="Student Population"
                data={data && data.StudentPopulation}
              />
              <DetailItem
                title="Accessibility"
                data={data && AccessibilityType[data.Accessibility]}
              />
            </div>
            <div className="contact">
              <DetailTitle detail="Contact Details" />
              <DetailItem
                title="Contact Person"
                data={data && data.Contactperson}
              />
              <DetailItem
                title="Designation"
                data={data && data.Designation === '' ? data.Designation : '-'}
              />
              <DetailItem
                title="Contact Number"
                data={data && data.Contactnumber}
              />
              <DetailItem title="Email" data={data && data.Email} />
            </div>
            <div className="action-buttons">
              <Button
                className="light-btn"
                onClick={() => {
                  dispatch({
                    type: 'SET_SELECTED_SCHOOL',
                    data,
                  });
                  history.push(
                    `/school/school-list/details/edit/${
                      data && data.CustomerId
                    }`
                  );
                }}
              >
                <PopoverMenuItem icon="edit">
                  <span style={{ marginLeft: '0.5rem' }}>Edit</span>
                </PopoverMenuItem>
              </Button>
              <Button className="light-btn" onClick={() => openDeleteModal()}>
                <PopoverMenuItem icon="delete">
                  <span style={{ marginLeft: '0.5rem' }}>Delete</span>
                </PopoverMenuItem>
              </Button>
            </div>
          </div>
          <div className="contracts">
            <div className="contract-title">
              <p>Contract</p>
              <Button
                className="primary-btn"
                onClick={() => {
                  dispatch({
                    type: 'SET_SELECTED_SCHOOL',
                    data,
                  });
                  if (data.ContractModels === null) {
                    history.push(
                      `/school/school-list/create-contract/${data.CustomerId}`
                    );
                  } else if (data.ContractModels.length > 0) {
                    history.push(
                      `/school/school-list/renew-contract/${data.CustomerId}`
                    );
                  }
                }}
              >
                {data !== null &&
                  (data.ContractModels !== null &&
                  data.ContractModels.length > 0
                    ? 'Renew'
                    : 'Add')}
              </Button>
            </div>
            <ContractTable
              data={data && data.ContractModels}
              openModal={openDeleteContractModal}
              schoolData={data}
            />
          </div>
        </>
      )}
      {!isLoading && error !== null && (
        <div className="error">
          <h2>{error}</h2>
        </div>
      )}
    </SchoolDetailsContainer>
  );
};

export default SchoolDetails;
