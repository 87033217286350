import styled from 'styled-components';

export const AccountManagerFormContainer = styled.div`
  padding: 0 50px;
  margin: 30px 0px;
  .date {
    margin-bottom: 10px;
    label {
      font-size: 14px;
      margin-bottom: 0px;
      padding-left: 5px;
    }
    .DateInput_input {
      font-size: 14px;
      padding: 2px 11px;
    }
  }
`;
