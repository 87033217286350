import styled from 'styled-components';

export const ContractMenuContainer = styled.div`
  justify-content: space-evenly;
  display: flex;
`;

export const PopoverMenuContainer = styled.div`
  position: relative;
  display: inline-block;
  &:hover .menu-item {
    display: block;
  }
  .popover-menu {
    font-size: 30px;
    position: relative;
    bottom: 0.5rem;
    border: none;
    background: transparent;
    color: #393939;
    outline: none;
    &:hover {
      color: #ff5b00;
    }
  }
  .menu-item {
    display: none;
    position: absolute;
    left: -2.5rem;
    background: #fff;
    min-width: 104.4px;
    padding: 0.5rem 1rem 1rem 1rem;
    margin-right: 1rem;
    box-shadow: 0 3px 6px rgba(45, 45, 45, 0.37);
    z-index: 100;
    .actions-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 0.875rem;
      font-weight: 600;
      color: #727272;
      cursor: pointer;
      margin-top: 16px;
      &:hover {
        color: #ff5b00;
      }
    }
  }
`;
