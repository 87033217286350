import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import avatar from 'assets/img/avatar.png';
import { logout } from 'services/login';
import { ProfileContainer, NavProfileContainer } from './NavProfile.styles';

const ProfileAvatar = ({ onClick }, ref) => (
  <ProfileContainer>
    <Link
      to="/"
      className="nav-link-avatar"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <img src={avatar} alt="profile" />
    </Link>
  </ProfileContainer>
);

const NavProfile = () => {
  const handleLogout = () => {
    logout();
  };

  return (
    <NavProfileContainer>
      <Dropdown className="drop-down">
        <Dropdown.Toggle
          as={forwardRef(ProfileAvatar)}
          id="dropdown-custom-components"
        />
        <Dropdown.Menu>
          <Dropdown.Item eventKey="3" onClick={handleLogout}>
            <i className="icon-logout" />
            Log out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </NavProfileContainer>
  );
};

export default NavProfile;
