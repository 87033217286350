import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/Shared/Button';
import { Spinner } from 'react-bootstrap';
import { FormInput, OptionInput, Checkbox } from 'components/Shared/FormInput';
import { addContract, renewContract, updateContract } from 'services/contracts';
import {
  parseCreateContractJSON,
  parseUpdateContractJSON,
} from 'helpers/parseContractJson';
import { AccountType, CurrencyList, PaymentStatus } from 'constants/index';
import Picker from 'react-month-picker';
import moment from 'moment';
import compareValues from 'helpers/sortArrayOfObjects';
import { ContractFormContainer, DatePickerInput } from './ContractForm.styles';
import 'react-month-picker/css/month-picker.css';
import { history } from '../../Routes';
import InfoModal from 'components/Shared/InfoModal';
import isAccountManagerInList from 'helpers/checkAccountManager';

// Helpers
const parseDateString = (date, type) => {
  const paddedMonth = date.month <= 9 ? `0${date.month}` : `${date.month}`;
  const day = type === 'start' ? '01' : '31';
  return `${date.year}-${paddedMonth}-${day}T00:00:00`;
};

const findLatestContract = (contracts) => {
  const sortedContracts = contracts
    .map((contract) => {
      return {
        id: contract.ContractId,
        contractEndDate: moment(contract.EndDate).unix(),
      };
    })
    .sort(compareValues('contractEndDate', 'desc'));
  return sortedContracts[0];
};

const getYearMonthFromDateString = (datestring) => {
  return {
    year: moment(datestring).year(),
    // month numbers from momentjs are zero-indexed
    month: moment(datestring).month() + 1,
  };
};

const getCurrentAccountQuota = (contractDetails, accountType) => {
  const lineItemIndex = contractDetails.findIndex(
    (lineItem) =>
      lineItem.AccountType === accountType && lineItem.ItemType === 'Account'
  );
  if (lineItemIndex !== -1) {
    return contractDetails[lineItemIndex].AccountsPurchased;
  }
  return 0;
};

const ContractForm = ({
  plans,
  accountManagers,
  defaultAccountManager,
  customerID,
  type,
  contracts,
  contractID,
  currentAccountManager,
}) => {
  const { register, handleSubmit } = useForm();
  const selectedContractIndex =
    type === 'update'
      ? contracts.findIndex((contract) => contract.ContractId === contractID)
      : null;
  const onSubmit = async (data) => {
    // Append additional fields such as dates
    const formData = {
      ...data,
      StartDate: parseDateString(contractRange.from, 'start'),
      EndDate: parseDateString(contractRange.to, 'end'),
      AcademicYearStartDate: parseDateString(rangeValue.from, 'start'),
      AcademicYearEndDate: parseDateString(rangeValue.to, 'end'),
      ContractNumber: '',
      DigitalCopy: '',
      CustomerId: customerID,
    };
    // If type is renew, add RefContractId
    if (type === 'renew') {
      formData.RefContractId = findLatestContract(contracts).id;
    }
    // Parse form data in api format
    let rawBody;
    if (type === 'new' || type === 'renew') {
      rawBody = parseCreateContractJSON(formData);
    } else if (type === 'update') {
      formData.ContractId = contractID;
      if (selectedContractIndex !== -1) {
        rawBody = parseUpdateContractJSON(
          formData,
          contracts[selectedContractIndex]
        );
      } else {
        console.log('Contract ID not found');
      }
    }
    if (rawBody !== null) {
      try {
        setAPILoading(true);
        let result;
        if (type === 'new') {
          result = await addContract(rawBody);
        } else if (type === 'renew') {
          result = await renewContract(rawBody);
        } else if (type === 'update') {
          result = await updateContract(rawBody);
        }
        setAPILoading(false);
        if (result) {
          history.push(`/school/school-list/details/${customerID}`);
        }
      } catch (error) {
        setAPILoading(false);
        setInfoModalMessage(error.message);
        setShowInfoModal(true);
      }
    }
  };

  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  // Date Picker
  let currentYear;
  let initialRangeValue;
  let initialContractRange;
  if (type !== 'update') {
    currentYear = moment().year();
    initialRangeValue = {
      from: { year: currentYear, month: 1 },
      to: { year: currentYear, month: 12 },
    };
    initialContractRange = {
      from: { year: currentYear, month: 1 },
      to: { year: currentYear, month: 12 },
    };
  } else {
    currentYear = contracts[selectedContractIndex].AcademicYear;
    initialRangeValue = {
      from: getYearMonthFromDateString(
        contracts[selectedContractIndex].AcademicYearStartDate
      ),
      to: getYearMonthFromDateString(
        contracts[selectedContractIndex].AcademicYearEndDate
      ),
    };
    initialContractRange = {
      from: getYearMonthFromDateString(
        contracts[selectedContractIndex].StartDate
      ),
      to: getYearMonthFromDateString(contracts[selectedContractIndex].EndDate),
    };
  }

  let monthpicker = null;
  let contractMonthPicker = null;
  const pickerLang = {
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    from: 'From',
    to: 'To',
  };
  // Stores selected academic date range
  const [rangeValue, setRangeValue] = useState(initialRangeValue);
  // Stores selected contract date range
  const [contractRange, setContractRange] = useState(initialContractRange);
  const handleRangeChange = (value, text, listIndex) => {
    if (listIndex === 0) {
      setRangeValue({
        ...rangeValue,
        from: {
          year: value,
          month: text,
        },
      });
    } else if (listIndex === 1) {
      setRangeValue({
        ...rangeValue,
        to: {
          year: value,
          month: text,
        },
      });
    }
  };
  const handleContractRangeChange = (value, text, listIndex) => {
    if (listIndex === 0) {
      setContractRange({
        ...rangeValue,
        from: {
          year: value,
          month: text,
        },
      });
    } else if (listIndex === 1) {
      setContractRange({
        ...rangeValue,
        to: {
          year: value,
          month: text,
        },
      });
    }
  };
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [InfoModalMessage, setInfoModalMessage] = useState(null);
  // Loading state for API on click of submit button
  const [isAPILoading, setAPILoading] = useState(false);
  return (
    <ContractFormContainer>
      <InfoModal
        msg={InfoModalMessage}
        show={showInfoModal}
        variant="danger"
        hide={() => {
          setShowInfoModal(false);
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {plans && (
            <OptionInput
              position="col"
              label="Plan Type"
              bind="PlanId"
              optionKey="PlanName"
              valueKey="ID"
              disabled={type === 'update'}
              defaultValue={
                type === 'update'
                  ? contracts[selectedContractIndex].PlanId
                  : null
              }
              options={plans}
              register={register({ required: true })}
              hasCustomOnChange
              onChange={(e) => {
                const newID = parseInt(e.target.value, 10);
                const newPlanIndex = plans.findIndex(
                  (item) => item.ID === newID
                );
                if (newPlanIndex !== -1) {
                  setSelectedPlan(plans[newPlanIndex]);
                }
              }}
            />
          )}
          <FormInput
            position="col"
            name="AcademicYear"
            label="Year"
            disabled={type === 'update'}
            type="number"
            defaultValue={currentYear}
            register={register({ required: true, min: 1 })}
          />
        </div>
        <OptionInput
          label="Account Manager"
          bind="AccountManagerId"
          disabled={type === 'update'}
          options={accountManagers}
          optionKey="name"
          defaultValue={defaultAccountManager}
          valueKey="id"
          register={register({ required: true })}
        >
          {isAccountManagerInList(defaultAccountManager, accountManagers) ===
            false && (
            <option value={currentAccountManager.id}>
              {currentAccountManager.name}
            </option>
          )}
        </OptionInput>
        <div className="row">
          <OptionInput
            position="col"
            label="Currency"
            bind="Currency"
            disabled={type === 'update'}
            defaultValue={
              type === 'update'
                ? contracts[selectedContractIndex].Currency === 0
                  ? 1
                  : contracts[selectedContractIndex].Currency
                : null
            }
            options={CurrencyList}
            optionKey="name"
            valueKey="id"
            register={register({ required: true })}
          />
          <FormInput
            position="col"
            name="TotalValue"
            label="Value"
            type="number"
            disabled={type === 'update'}
            defaultValue={
              type === 'update'
                ? contracts[selectedContractIndex].TotalValue
                : null
            }
            register={register({ required: true, min: 0.01 })}
            step="0.01"
          />
          <FormInput
            position="col"
            label="Transition Period"
            name="TransitionPeriod"
            type="number"
            value={
              type === 'update'
                ? contracts[selectedContractIndex].TransitionPeriod
                : 60
            }
            disabled
            register={register({ required: true })}
          />
          <Checkbox
            position="col"
            label="Paid"
            name="PaymentStatus"
            checked={
              type === 'update'
                ? PaymentStatus[
                    contracts[selectedContractIndex].PaymentStatus
                  ] === 'Paid'
                : true
            }
            disabled
            register={register({ required: true })}
          />
        </div>
        <div className="date">
          <label>Academic Date</label>
          <Picker
            value={rangeValue}
            ref={(ref) => {
              monthpicker = ref;
            }}
            years={{
              min: {
                year: 2000,
                month: 1,
              },
              max: {
                year: 2100,
                month: 12,
              },
            }}
            lang={pickerLang.months}
            theme="light"
            onChange={handleRangeChange}
          >
            <DatePickerInput onClick={() => monthpicker && monthpicker.show()}>
              {`${rangeValue.from.month}/${rangeValue.from.year} - ${rangeValue.to.month}/${rangeValue.to.year}`}
            </DatePickerInput>
          </Picker>
        </div>
        {selectedPlan.AccountTypes.length > 0 &&
          selectedPlan.AccountTypes.map((accountType) => (
            <FormInput
              key={accountType}
              label={`Number of ${AccountType[accountType]} Accounts`}
              type="number"
              name={`acctQuota-${accountType}`}
              register={register({ required: true, min: 0 })}
              defaultValue={
                type === 'update'
                  ? getCurrentAccountQuota(
                      contracts[selectedContractIndex].ContractDetails,
                      accountType
                    )
                  : 0
              }
            />
          ))}
        <div className="date">
          <label>Contract Date</label>
          <Picker
            value={contractRange}
            ref={(ref) => {
              contractMonthPicker = ref;
            }}
            years={{
              min: {
                year: 2000,
                month: 1,
              },
              max: {
                year: 2100,
                month: 12,
              },
            }}
            lang={pickerLang.months}
            theme="light"
            onChange={handleContractRangeChange}
          >
            <DatePickerInput
              onClick={() => contractMonthPicker && contractMonthPicker.show()}
            >
              {`${contractRange.from.month}/${contractRange.from.year} - ${contractRange.to.month}/${contractRange.to.year}`}
            </DatePickerInput>
          </Picker>
        </div>
        <FormInput
          label="Remarks"
          type="text"
          name="Remarks"
          defaultValue={
            type === 'update' ? contracts[selectedContractIndex].Remarks : null
          }
          register={register()}
        />
        <div className="btn">
          <Button variant="primary-btn" type="submit">
            {isAPILoading && <Spinner animation="grow" size="sm" />}
            <span style={{ marginLeft: '0.5rem' }}>
              {type === 'update' ? 'Save' : 'Create Contract'}
            </span>
          </Button>
        </div>
      </form>
    </ContractFormContainer>
  );
};

export default ContractForm;
