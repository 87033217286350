import React, { useReducer, createContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import Topbar from 'components/Topbar/Topbar';
import Sidebar from 'components/Sidebar/Sidebar';
import SidebarSchool from 'components/Sidebar/SidebarSchool';
import SchoolList from 'pages/School/SchoolList';
import SchoolDetails from 'pages/School/SchoolDetails';
import CreateSchool from 'pages/School/CreateSchool';
import UpdateSchool from 'pages/School/UpdateSchool';
import CreateExistingSchool from 'pages/School/CreateExistingSchool';
import CreateContract from 'pages/School/CreateContract';
import UpdateContract from 'pages/School/UpdateContract';
import { SchoolContainer } from './School.styles';

// Reducers
const selectedSchoolReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_SCHOOL':
      return action.data;
    default:
      return state;
  }
};

export const SelectedSchoolDispatch = createContext(null);

const School = () => {
  const [selectedSchool, dispatchSelectedSchools] = useReducer(
    selectedSchoolReducer,
    null
  );

  return (
    <>
      <Topbar />
      <Sidebar>
        <SidebarSchool />
      </Sidebar>
      <SelectedSchoolDispatch.Provider value={dispatchSelectedSchools}>
        <SchoolContainer>
          <Switch>
            <Route exact path="/school" component={SchoolList} />
            <Route exact path="/school/school-list" component={SchoolList} />
            <Route
              exact
              path="/school/school-list/details/:id"
              component={(props) => <SchoolDetails {...props} />}
            />
            <Route
              exact
              path="/school/create-school"
              component={(props) => (
                <CreateSchool {...props} selectedSchool={selectedSchool} />
              )}
            />
            <Route
              exact
              path="/school/add-school/:name"
              component={CreateExistingSchool}
            />
            <Route
              exact
              path="/school/school-list/details/edit/:id"
              component={(props) => (
                <UpdateSchool {...props} selectedSchool={selectedSchool} />
              )}
            />
            <Route
              exact
              path="/school/school-list/create-contract/:id"
              component={(props) => (
                <CreateContract
                  {...props}
                  type="new"
                  selectedSchool={selectedSchool}
                />
              )}
            />
            <Route
              exact
              path="/school/school-list/renew-contract/:id"
              component={(props) => (
                <CreateContract
                  {...props}
                  type="renew"
                  selectedSchool={selectedSchool}
                />
              )}
            />
            <Route
              exact
              path="/school/school-list/update-contract/:contractID"
              component={(props) => (
                <UpdateContract {...props} selectedSchool={selectedSchool} />
              )}
            />
          </Switch>
        </SchoolContainer>
      </SelectedSchoolDispatch.Provider>
    </>
  );
};

export default School;
