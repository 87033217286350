import React from 'react';
import { ButtonStyle } from './Button.styles';

const Button = (props) => {
  const { variant, children, ...rest } = props;
  return (
    <ButtonStyle>
      <button className={`${variant}`} {...rest}>
        {children}
      </button>
    </ButtonStyle>
  );
};

export default Button;
