import React, { useState, useEffect } from 'react';
import FormPageTopbar from 'components/Shared/FormPageTopbar';
import ContractForm from 'components/School/ContractForm';
import { getPlans } from 'services/contracts';
import { getAccountManagers } from 'services/account-manager';

const UpdateSchool = ({ match, selectedSchool }) => {
  const contractID = parseInt(match.params.contractID, 10);
  const { School_Id, ContractModels } = selectedSchool;

  const selectedContractIndex = ContractModels.findIndex(
    (contract) => contract.ContractId === contractID
  );
  const currentAccountManager =
    selectedContractIndex !== -1
      ? ContractModels[selectedContractIndex].AccountManagerId
      : null;

  // States storing data from API
  const [plans, setPlans] = useState(null);
  const [accountManagers, setAccountManagers] = useState(null);

  useEffect(() => {
    getPlans(School_Id).then((res) => {
      setPlans(res);
    });
  }, [School_Id]);
  useEffect(() => {
    getAccountManagers().then((res) => {
      setAccountManagers(res.Result.List);
    });
  }, []);
  return (
    <>
      <FormPageTopbar
        nav="/school/school-list"
        title={`Update Contract for ${selectedSchool.SchoolName}`}
      />
      {plans && accountManagers && (
        <ContractForm
          type="update"
          plans={plans}
          defaultAccountManager={currentAccountManager}
          currentAccountManager={{
            id: currentAccountManager,
            name:
              selectedContractIndex !== -1
                ? ContractModels[selectedContractIndex].AccountManagerName
                : null,
          }}
          accountManagers={accountManagers}
          contracts={ContractModels}
          contractID={contractID}
          customerID={selectedSchool.CustomerId}
        />
      )}
    </>
  );
};

export default UpdateSchool;
