export const compareValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export const sortCountry = (schoolList) => {
  const sortedCountry = [];
  for (const schoolObject of schoolList) {
    const hasCountry = sortedCountry.findIndex(
      (item) => item.country === schoolObject.CountryName
    );
    if (hasCountry === -1) {
      sortedCountry.push({
        country: schoolObject.CountryName,
        school: [schoolObject],
      });
    } else {
      sortedCountry[hasCountry].school.push(schoolObject);
    }
  }
  return sortedCountry;
};
