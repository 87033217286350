import styled from 'styled-components';

const NavLinkContainer = styled.div`
  .nav-link {
    color: white;
    padding: 10px 15px;
    display: block;
    text-decoration: none;
    :hover {
      background-color: #ff6e00;
    }
  }
  img {
    margin-right: 8px;
  }
  span {
    margin-left: 3px;
    @media (max-width: 971px) {
      display: none;
    }
  }
  .active {
    background-color: #ff6e00;
  }
`;

export default NavLinkContainer;
