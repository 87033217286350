import React from 'react';
import Routes from './Routes';
import 'assets/fonts/fonts.css';
import { GlobalStyle } from 'assets/styles/GlobalStyles.styles';

function App() {
  return (
    <>
      <GlobalStyle />
      <Routes />
    </>
  );
}

export default App;
