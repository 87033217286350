import React, { useState } from 'react';
import { login } from 'services/login';
import { LoginFormContainer, InputContainer } from './LoginForm.styles';
import { history } from '../../../../Routes';

const Password = ({ password, handleChange }) => {
  const [type, setType] = useState('password');
  const togglePasswordHandler = (e) => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };

  return (
    <InputContainer>
      <input
        type={type}
        name="password"
        id="password"
        placeholder="Enter your password"
        onChange={handleChange}
        value={password}
        required
      />
      <div className="show-password">
        <input
          type="checkbox"
          name="show-password"
          onClick={togglePasswordHandler}
        />
        <span>Show</span>
      </div>
    </InputContainer>
  );
};

const LoginErrors = ({ error, username }) => {
  const errorTypes = {
    'Invalid Password.': {
      message:
        'Your Password is wrong. Forgot password? Please contact Koobits Admin',
    },
    'Invalid Username.': {
      message: 'Your Username is invalid.',
      url: {
        link:
          'https://support.koobits.com/hc/en-gb/articles/212117966-Forgot-Login-ID-',
        text: 'Check Login ID Format',
      },
    },
    'connection-issue': {
      message: "Sorry, we can't connect to internet or our server.",
    },
    "You don't have permission to access": {
      message: 'Sorry, your account does not have permission to access.',
    },
  };
  return (
    <div
      style={{
        color: '#FF2E35',
        paddingLeft: '1rem',
        marginBottom: '0.5rem',
        textAlign: 'left',
      }}
    >
      {errorTypes.hasOwnProperty(error)
        ? `${errorTypes[error].message} `
        : error}
      {errorTypes.hasOwnProperty(error) &&
        errorTypes[error].hasOwnProperty('url') && (
          <a href={errorTypes[error].url.link} style={{ color: '#FF2E35' }}>
            {errorTypes[error].url.text}
          </a>
        )}
    </div>
  );
};

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === 'login-id') {
      setUsername(value);
    } else {
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      setIsLoading(true);
      const result = await login(username, password);
      localStorage.setItem('access_token', result.access_token);
      localStorage.setItem('refresh_token', result.refresh_token);
      setIsLoading(false);
      history.push('/school');
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  };
  return (
    <LoginFormContainer>
      <form className="login-form" onSubmit={handleSubmit}>
        <InputContainer>
          <input
            type="text"
            name="login-id"
            id="login-id"
            placeholder="Enter your Login ID"
            onChange={handleChange}
            value={username}
            required
          />
        </InputContainer>
        <Password password={password} handleChange={handleChange} />
        {error !== null && <LoginErrors error={error} username={username} />}
        <button className="login-button" type="submit">
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </LoginFormContainer>
  );
};

export default LoginForm;
