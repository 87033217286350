import styled from 'styled-components';

const InfoModalStyle = styled.div`
  text-align: center;
  p {
    font-size: 20px;
    font-weight: 600;
  }
  .danger {
    color: #ff5b00;
  }
  .info {
    color: #007bff;
  }
  .warning {
    color: #ffc107;
  }
  .success {
    color: #28a745;
  }
`;

export default InfoModalStyle;
