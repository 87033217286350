import styled from 'styled-components';

export const AuthorisedListTableContainer = styled.div`
  padding: 50px;
  .table td,
  .table th {
    padding: 5px;
    vertical-align: middle;
    border: 1px solid #ffd98d;
  }
  thead {
    tr {
      background: #ffcc68;
      text-align: center;
    }
  }
  tbody {
    tr {
      text-align: center;
    }
    tr:nth-child(odd) {
      background-color: #ffffff;
    }
    tr:nth-child(even) {
      background-color: #fffcf6;
    }
  }
`;
