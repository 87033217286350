import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { NavItemContainer } from './NavItem.styles';

const NavItem = ({ isActive, to, children }) => {
  const classname = ['nav-item', 'text-icon'];
  if (isActive) classname.push('active');
  if (isActive && to === '/dashboard') classname.push('dashboard');
  return (
    <NavItemContainer>
      <li className={classname.join(' ')}>
        <Link className="nav-link" to={to}>
          {children}
        </Link>
      </li>
    </NavItemContainer>
  );
};

export default withRouter(({ location, match, ...props }) => {
  let isActive = location.pathname.slice(0, props.to.length) === props.to;
  return <NavItem {...props} isActive={isActive} />;
});
