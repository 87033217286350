import React from 'react';
import { range } from 'ramda';
import { PaginationContainer } from './Pagination.styles';

function getPaginationNumbers(maxPage, numVisiblePages, page) {
  // Generate page numbers, e.g. if maxPage === 3, show pages number 1 to 3
  const pageNumbers = range(1, maxPage + 1);

  // If we have less total pages than number of visible pages, show all page numbers generated
  if (maxPage <= numVisiblePages) {
    return pageNumbers;
  }

  // If current page is at beginning portion of possible page numbers, show the beginning portion
  if (page < numVisiblePages) {
    return pageNumbers.slice(0, numVisiblePages);
  }

  // If current page is at the end portion of possible page numbers, show the end portion
  // Note: not limiting current page to max page number
  if (page > maxPage - (numVisiblePages - 1)) {
    const firstIndex = pageNumbers.indexOf(maxPage - numVisiblePages) + 1;
    return pageNumbers.slice(firstIndex);
  }

  // If below code runs, current page must be in the middle portion of possible page numbers
  // show the page numbers adjacent to the current page
  const numPagesOnLeft = Math.floor(numVisiblePages / 2);
  const numPagesOnRight = numVisiblePages - numPagesOnLeft;
  const firstIndex = pageNumbers.indexOf(page) - numPagesOnLeft;
  const lastIndex = pageNumbers.indexOf(page) + numPagesOnRight;
  return pageNumbers.slice(firstIndex, lastIndex);
}

function PaginationShared(props) {
  const {
    maxPage,
    numVisiblePages,
    onPageChange,
    onSearchPageChange,
    page,
    searchPage,
  } = props;

  const pages = getPaginationNumbers(maxPage, numVisiblePages, page);

  return (
    <PaginationContainer>
      <nav aria-label="Page navigation" className="page-nav">
        <ul className="pagination table-pagination">
          <li className="page-item pagination-prev">
            <button
              type="button"
              className="page-link"
              disabled={page === 1}
              onClick={() => onPageChange(page - 1)}
            >
              &lt; Prev
            </button>
          </li>
          {pages.map((pageNumber) => {
            return (
              <li
                key={String(pageNumber)}
                className={`page-item ${pageNumber === page ? 'active' : ''}`}
              >
                <button
                  type="button"
                  className="page-link"
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            );
          })}
          <li className="page-item pagination-next">
            <button
              type="button"
              className="page-link"
              disabled={maxPage <= page}
              onClick={() => onPageChange(page + 1)}
            >
              Next &gt;
            </button>
          </li>
          <li className="page-nav__search" style={{ marginLeft: 10 }}>
            Page
            <input
              type="text"
              value={searchPage}
              max={maxPage}
              onChange={(evt) => onSearchPageChange(evt.target.value)}
            />
            of {maxPage}
          </li>
        </ul>
      </nav>
    </PaginationContainer>
  );
}

PaginationShared.defaultProps = {
  numVisiblePages: 3,
};

export default PaginationShared;
