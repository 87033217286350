import React from 'react';
import { InputContainer, CheckboxStyle } from './FormInput.styles';

export const FormInput = ({
  label,
  type,
  position,
  name,
  value,
  defaultValue,
  onChange,
  disabled,
  required,
  register,
  step,
}) => {
  return (
    <InputContainer className={`form-group ${position}`}>
      <label>{label}</label>
      <input
        type={type}
        className="form-control form-control-sm"
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={(e) => {
          if (onChange !== undefined) {
            onChange(e.target.value, name);
          }
        }}
        disabled={disabled}
        required={required}
        ref={register}
        step={step}
      />
    </InputContainer>
  );
};

export const OptionInput = ({
  label,
  options,
  name,
  bind,
  position,
  onChange,
  disabled,
  optionKey,
  valueKey,
  register,
  defaultValue,
  value,
  hasCustomOnChange,
  children,
  required,
}) => {
  return (
    <InputContainer className={`form-group ${position}`}>
      <label>{label}</label>
      <select
        className="form-control form-control-sm"
        id={bind}
        name={bind}
        disabled={disabled}
        onChange={(e) => {
          if (hasCustomOnChange === true) {
            onChange(e);
          } else if (onChange !== undefined) {
            onChange(e.target.value, name);
          }
        }}
        ref={register}
        defaultValue={defaultValue}
        value={value}
        required={required}
      >
        {children}
        {options &&
          options.map((item, index) => (
            <option key={index} value={valueKey ? item[valueKey] : item}>
              {optionKey ? item[optionKey] : item}
            </option>
          ))}
      </select>
    </InputContainer>
  );
};

export const Checkbox = ({
  onChange,
  label,
  position,
  checked,
  disabled,
  name,
  register,
}) => {
  return (
    <CheckboxStyle className={`form-group ${position}`}>
      <input
        type="checkbox"
        className="check-box"
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        ref={register}
      />
      <label className="form-check-label">{label}</label>
    </CheckboxStyle>
  );
};
