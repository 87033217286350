import React, { useState, useEffect } from 'react';
import FormPageTopbar from 'components/Shared/FormPageTopbar';
import AuthorisedListTable from 'components/AccountManager/AuthorisedListTable';
import { getAccountManagerById } from 'services/account-manager';
import { useParams } from 'react-router-dom';
import Spinner from 'components/Shared/Spinner';

const AuthorisedSchoolList = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [schoolData, setSchoolData] = useState([]);

  const setAccessibility = (val) => {
    if (val === 1) return 'Inaccessible';
    if (val === 2) return 'Not accessible';
    if (val === 3) return 'Somewhat accessible';
    if (val === 4) return 'Easily accessible';
    if (val === 5) return 'Most accessible';
    return null;
  };

  const bindData = (val) => {
    const tempData = val.map((elem) => {
      const obj = {
        countryName: elem.CountryName,
        schoolId: elem.School_Id,
        schoolName: elem.SchoolName,
        studentPopulation: elem.StudentPopulation,
        accessibility: setAccessibility(elem.Accessibility),
        status: elem.ContractModels != null ? 'Customer' : 'Prospect',
      };
      return obj;
    });
    setSchoolData([...schoolData, ...tempData]);
  };

  useEffect(() => {
    setIsLoading(true);
    if (params.id) {
      getAccountManagerById(params.id)
        .then((res) => {
          if (res.IsSuccessful) {
            bindData(res.Result.Customers);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log('err is', err);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('schoolData is===>', schoolData);
  return (
    <>
      <FormPageTopbar
        nav="/account-manager/account-manager/edit"
        title="Authorised School List"
        type="authorised-school"
      />
      {schoolData.length === 0 ? (
        <p
          className="no-list"
          style={{
            color: '#a4a4a4',
            fontSize: '32px',
            textAlign: 'center',
            marginTop: '50px',
          }}
        >
          No Authorised School
        </p>
      ) : (
        <>
          {isLoading ? <Spinner /> : <AuthorisedListTable data={schoolData} />}
        </>
      )}
    </>
  );
};

export default AuthorisedSchoolList;
