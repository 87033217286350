export const getSchools = async () => {
  return await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/ManageCustomers/GetCustomers`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get schools failed');
    });
};

export const getSchoolById = async (id) => {
  return await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/ManageCustomers/GetCustomerById/${id}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get school by ID failed');
    });
};

export const getExistingSchool = async (params) => {
  return await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/ManageCustomers/GetSchoolsByName/${params}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createSchool = async (rawBody) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/ManageCustomers/CreateCustomer`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(rawBody),
      }
    );
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateSchool = async (rawBody, id) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/ManageCustomers/UpdateCustomerSchool/${id}`,
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(rawBody),
      }
    );
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSchool = async (id) => {
  return await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/ManageCustomers/DeleteCustomerSchool/${id}`,
    {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
