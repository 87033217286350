import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Shared/Button';
import { SelectedSchoolDispatch } from 'pages/School/School';
import PopoverMenuIcon from './PopoverMenuIcon';
import {
  ContractMenuContainer,
  PopoverMenuContainer,
} from './ActionButtons.styles';

export const ContractMenu = ({ type, onClick, customerID, data }) => {
  const history = useHistory();
  const dispatch = useContext(SelectedSchoolDispatch);
  return (
    <ContractMenuContainer>
      <Button className="secondary-btn view" onClick={onClick}>
        View
      </Button>
      {type !== 'account-manager' && (
        <Button
          className="primary-btn add"
          onClick={() => {
            dispatch({
              type: 'SET_SELECTED_SCHOOL',
              data,
            });
            if (data.ContractModels === null) {
              history.push(`/school/school-list/create-contract/${customerID}`);
            } else if (data.ContractModels.length > 0) {
              history.push(`/school/school-list/renew-contract/${customerID}`);
            }
          }}
        >
          Add
        </Button>
      )}
    </ContractMenuContainer>
  );
};

export const PopoverMenuItem = ({ icon, children }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div
      className="actions-item"
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
    >
      <PopoverMenuIcon icon={icon} fill={isActive ? '#FF5B00' : '#747474'} />
      {children}
    </div>
  );
};

export const PopoverMenu = ({ openModal, onClick, deleteId }) => {
  return (
    <PopoverMenuContainer>
      <button type="button" className="popover-menu">
        ...
      </button>
      <div className="menu-item">
        <PopoverMenuItem icon="edit">
          <span onClick={onClick} style={{ marginLeft: '1rem' }}>
            Edit
          </span>
        </PopoverMenuItem>
        <PopoverMenuItem icon="delete">
          <span
            onClick={() => openModal(deleteId)}
            style={{ marginLeft: '1rem' }}
          >
            Delete
          </span>
        </PopoverMenuItem>
      </div>
    </PopoverMenuContainer>
  );
};
