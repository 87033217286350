import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  #login-id {
    background: #fff;
    padding: 8px 5px;
    border: none;
    border-bottom: 1px solid #828282;
    font-size: 18px;
    color: #393939;
    width: 100%;
    margin-bottom: 15px;
    outline: none;
  }
  #password {
    background: #fff;
    padding: 8px 5px;
    border: none;
    border-bottom: 1px solid #828282;
    font-size: 18px;
    color: #393939;
    width: calc(100% - 4rem);
    margin-bottom: 15px;
    outline: none;
  }
  .show-password {
    margin-top: 6px;
    input[type='checkbox'] {
      width: 12px;
      height: 12px;
      position: absolute;
      z-index: 1;
      margin-left: 15px;
      margin-top: 8px;
      cursor: pointer;
      /* Increase size of checkbox */
      -ms-transform: scale(2); /* IE */
      -moz-transform: scale(2); /* FF */
      -webkit-transform: scale(2); /* Safari and Chrome */
      -o-transform: scale(2); /* Opera */
      transform: scale(2);
    }
    span {
      margin-left: 40px;
      font-size: 15px;
      opacity: 1;
    }
  }
`;
export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffff;
  align-items: center;
  width: 90%;
  margin-top: 20px;
  .login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 20px;
  }
  .login-button {
    margin: auto;
    width: 100%;
    background-color: #ff5b00;
    border: none;
    outline: none;
    font-size: 24px;
    color: #ffff;
    padding: 5px;
    border-radius: 5px;
    &:hover {
      background-color: #f25008;
    }
  }
`;
