import styled from 'styled-components';

export const UpdateAccountManagerContainer = styled.div`
  .top-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0px 50px;
    .action-buttons {
      display: flex;
      flex-direction: row;
      button {
        margin-right: 25px;
        padding: 0px 25px;
        height: 40px;
        font-size: 0.875rem;
        font-weight: 600;
        color: #727272;
        &:hover {
          color: #ff5b00;
        }
      }
    }
  }
`;
