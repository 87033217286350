import React from 'react';
import Button from 'components/Shared/Button';
import SuccessIcon from 'assets/img/modal-success.svg';
import SuccessModalContainer from './SuccessModalBody.styles';

const SuccessModal = ({ onClick, type }) => {
  return (
    <SuccessModalContainer>
      <div className="title">
        <img src={SuccessIcon} alt="icon"></img>
      </div>
      <div className="subtitle">
        {type === 'create-school' || type === 'update-school'
          ? 'School'
          : 'Account Manager'}{' '}
        succesfully{' '}
        {type === 'create-school' || type === 'create-account-manager'
          ? 'created'
          : 'updated'}
        !
      </div>
      <div className="buttons">
        <Button className="primary-btn yes-btn" onClick={onClick}>
          OK
        </Button>
      </div>
    </SuccessModalContainer>
  );
};

export default SuccessModal;
