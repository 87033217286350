import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import NavLinkContainer from './SidebarNavLink.styles';

const SidebarNavLink = (props) => {
  const { nav, path: url, activeIcon, icon } = props;
  const { pathname } = useLocation();
  const isActive = (path) => pathname.match(path);

  return (
    <NavLinkContainer>
      <Link className={`nav-link ${isActive(url) ? 'active' : null}`} to={url}>
        {isActive(url) ? (
          <img src={activeIcon} alt="active-icon"></img>
        ) : (
          <img src={icon} alt="icon"></img>
        )}
        <span>{nav}</span>
      </Link>
    </NavLinkContainer>
  );
};

export default SidebarNavLink;
