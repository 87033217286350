import styled from 'styled-components';

export const PaginationContainer = styled.div`
  .page-nav {
    width: calc(100% - 50px);
    .pagination {
      justify-content: flex-end;
    }

    .page-item {
      margin: 0 0.5em;

      &.active .page-link,
      &.active .page-link:focus,
      &.active .page-link:hover {
        background-color: #ff6243;
        color: #fff;
      }

      .page-link {
        color: #343434;
        border-color: #d5d5d5;

        &:focus,
        &:hover {
          box-shadow: none;
          background-color: #fff7f3;
        }

        &:disabled {
          background-color: #fff;
          color: #d8d8d8;
        }
      }
    }

    &__search {
      align-self: center;
      margin-left: 1.5em;
      color: #343434;

      input {
        width: 2em;
        margin: 0 0.25em;
        text-align: center;
      }
    }
  }
`;
