import React from 'react';
import { SpinnerContainer } from './Spinner.styles';

const Spinner = ({ top, position, gridColumn }) => (
  <SpinnerContainer top={top} position={position} gridColumn={gridColumn}>
    Loading...
  </SpinnerContainer>
);

export default Spinner;
