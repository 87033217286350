import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FormPageTopbar from 'components/Shared/FormPageTopbar';
import SchoolForm from 'components/School/SchoolForm';
import PopupModal from 'components/Shared/PopupModal';
import SuccessModalBody from 'components/Shared/SuccesModalBody';
import { getAccountManagers } from 'services/account-manager';

const CreateExistingSchool = ({ match }) => {
  const schoolName = match.params.name;
  const [AMData, setAMData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const showModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    getAccountManagers().then((res) => {
      setAMData(res.Result.List);
    });
  }, []);
  return (
    <>
      <PopupModal show={modalOpen} hide={closeModal}>
        <SuccessModalBody
          onClick={() => {
            closeModal();
            history.push('/school/school-list');
          }}
          hide={closeModal}
          type="create-school"
        ></SuccessModalBody>
      </PopupModal>
      <FormPageTopbar
        nav="/school/school-list"
        title="School Info"
      ></FormPageTopbar>
      <SchoolForm
        type="existing-school"
        ExistingSchool={schoolName}
        accountManagers={AMData}
        openModal={showModal}
      ></SchoolForm>
    </>
  );
};

export default CreateExistingSchool;
