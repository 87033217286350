import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

export const StyledDropdown = styled(Dropdown)`
  #dropdown-custom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #aaaaaa;
    border-radius: 0px;
    background-color: #ffffff;
    outline: none;
    &:focus {
      box-shadow: none;
    }
  }
  .dropdown-toggle::after {
    margin-left: 30px;
  }
  .dropdown-item-custom {
    width: ${(props) => (props.width ? props.width : '230px')};
  }
`;
