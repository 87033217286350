import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DropdownMenu from 'components/Shared/DropdownMenu';
import PopupModal from 'components/Shared/PopupModal';
import DeleteModal from 'components/School/DeleteModal';
import AccountManagerListTable from 'components/AccountManager/AMListTable';
import Button from 'components/Shared/Button';
import InfoModal from 'components/Shared/InfoModal';
import Pagination from 'components/Shared/Pagination';
import {
  getAccountManagers,
  delAccountManager,
} from 'services/account-manager';
import Spinner from 'components/Shared/Spinner';
import refreshFetchWrapper from 'services/refreshToken';
import { ListContainer } from './AMList.styles';

const countryData = [
  'ALL',
  'SINGAPORE',
  'INDONESIA',
  'PHILIPPINES',
  'VIETNAM',
  'MALAYSIA',
  'MYANMAR',
  'AUSTRALIA',
];
const statusData = ['Active', 'Non-active'];
let deletedId = null;

const AccountManagerList = () => {
  const [data, setData] = useState(null);
  const [activeData, setActiveData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    msg: '',
    variant: '',
  });
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    refreshFetchWrapper(getAccountManagers)
      .then((res) => {
        setIsLoading(false);
        setData(res.Result.List);
        setActiveData(res.Result.List);
      })
      .catch((err) => console.log('err is', err));
  }, []);

  // modal state & handlers
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const openDeleteModal = (id) => {
    deletedId = id;
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // pagination
  const maxDataPages = Math.ceil(activeData && activeData.length / 20);

  // display max number of data
  const perPage = 20;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dataSlice = activeData && activeData.slice(offset, offset + perPage);

  // Callback for page change
  const onPageChange = (number) => {
    const newOffset = (number - 1) * perPage;
    setCurrentPage(number);
    setOffset(newOffset);
  };

  // dropdown state & handlres
  const [country, setCountry] = useState(null);
  const handleSelectCountry = (activeCountry) => {
    let res = [];
    if (activeCountry === 'ALL') res = data;
    else {
      res = data.filter((elem) => {
        if (elem.countryName === activeCountry) {
          return elem;
        }
        return null;
      });
    }
    setActiveData(res);
    setCountry(activeCountry);
  };

  const [status, setStatus] = useState(null);
  const handleSelectStatus = (activeStatus) => {
    const res = data.filter((elem) => {
      if (activeStatus === 'Active') return elem.status === 1;
      return elem.status === 0;
    });
    setActiveData(res);
    setStatus(activeStatus);
  };

  const deleteData = () => {
    setIsLoading(true);
    delAccountManager(deletedId)
      .then((res) => {
        const index = data.findIndex((elem) => elem.id === deletedId);
        const tempData = [...activeData];
        tempData.splice(index, 1);
        setActiveData(tempData);
        setIsLoading(false);
        hideDeleteModal();
      })
      .catch((err) => {
        setIsLoading(false);
        hideDeleteModal();
        setAlert({ ...alert, show: true, msg: err.message, variant: 'danger' });
      });
  };

  return (
    <ListContainer>
      <PopupModal show={showDeleteModal} hide={hideDeleteModal}>
        <DeleteModal
          type="account-manager"
          onClick={deleteData}
          hide={hideDeleteModal}
        />
      </PopupModal>
      <div className="top-section">
        <div>Account Manager List</div>
        <Button
          onClick={() =>
            history.push('/account-manager/create-account-manager')
          }
          className="primary-btn"
        >
          Add
        </Button>
      </div>
      <div className="filter-section">
        <div className="status">
          <p>Filter by Status :</p>
          <DropdownMenu
            className="dropdown"
            selectedValue={status === null ? 'Select Status' : status}
            valueKey={null}
            values={statusData}
            setValue={handleSelectStatus}
            width="160px"
          />
        </div>
        <div className="country">
          <p>Filter by Country :</p>
          <DropdownMenu
            className="dropdown"
            selectedValue={country === null ? 'Select Country' : country}
            valueKey={null}
            values={countryData}
            setValue={handleSelectCountry}
            width="160px"
          />
        </div>
      </div>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <AccountManagerListTable
            data={dataSlice}
            openModal={openDeleteModal}
          />
          <Pagination
            maxPage={maxDataPages}
            onPageChange={onPageChange}
            onSearchPageChange={onPageChange}
            page={currentPage}
            searchPage={currentPage}
          />
        </>
      )}
      {alert.show && (
        <InfoModal
          msg={alert.msg}
          show={alert.show}
          variant={alert.variant}
          hide={() => setAlert({ ...alert, show: false })}
        />
      )}
    </ListContainer>
  );
};

export default AccountManagerList;
