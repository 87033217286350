import styled from 'styled-components';

export const NavItemContainer = styled.div`
  .nav-item {
    padding: 10px;
    margin-right: 10px;
    @media (max-width: 1024px) {
      margin-right: 30px;
    }
    @media (max-width: 650px) {
      margin-right: 10px;
    }
    .nav-link {
      font-size: 16px;
      color: white;
      position: relative;
      margin: 0px 25px;
      line-height: 1;
      text-align: center;
      text-decoration: none;
      @media (max-width: 1280px) {
        margin: 0px 0px;
      }
      @media (max-width: 1024px) {
        span {
          font-size: 13px;
        }
      }
      @media (max-width: 1000px) {
        span {
          font-size: 10px;
        }
      }
      img {
        margin-right: 10px;
      }
      .icon {
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        width: 20px;
        height: 20px;
      }
    }
    &:hover {
      span {
        color: #ffc400;
      }
      background-color: #343434;
    }
  }
  .active {
    span {
      color: #ffc400;
    }
    background-color: #343434;
  }
`;
