import styled from 'styled-components';

export const TopbarContainer = styled.nav`
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  padding: 0px 30px;
  background-color: #4a4947;
  box-shadow: 0px 1px 4px rgba(#000, 0.16);
  z-index: 100;
  align-items: center;
  .navbar-brand {
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    margin: 0 100px 0 0;
    .admin {
      color: #ffc400;
      font-size: 16px;
      margin-left: 5px;
      margin-bottom: 3px;
    }
  }
`;
