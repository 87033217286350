import styled from 'styled-components';

const SchoolListContainer = styled.div`
  .top-section {
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;
    background: white;
    .search-bar {
      border: 1px solid #aaaaaa;
      border-radius: 3px;
      padding: 5px 10px;
      outline: none;
    }
  }
  .filter-section {
    display: flex;
    padding: 20px 50px;
    p {
      font-weight: bold;
      margin-right: 20px;
      margin-bottom: 0px;
      margin-top: 5px;
      font-size: 16px;
    }
  }
`;

export default SchoolListContainer;
