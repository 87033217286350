export const getPlans = async (schoolID) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/ManageCustomers/GetCustomerPlans/${schoolID}`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    );
    const result = await res.json();
    if (result.IsSuccessful) {
      return result.Result;
    }
    throw new Error(result.Message.Message);
  } catch (err) {
    throw new Error(err?.message ?? 'Get plans by school ID failed');
  }
};

export const addContract = async (rawBody) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/Contract/AddContract/`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rawBody),
      }
    );
    const result = await res.json();
    if (result.IsSuccessful) {
      return result.Result;
    }
    throw new Error(result.Message.Message);
  } catch (err) {
    throw new Error(err?.message ?? 'Add new contract failed');
  }
};

export const renewContract = async (rawBody) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/Contract/RenewContract/`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rawBody),
      }
    );
    const result = await res.json();
    if (result.IsSuccessful) {
      return result.Result;
    }
    throw new Error(result.Message.Message);
  } catch (err) {
    throw new Error(err?.message ?? 'Renew contract failed');
  }
};

export const updateContract = async (rawBody) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/Contract/UpdateContract/`,
      {
        method: 'PUT',
        headers: {
          authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rawBody),
      }
    );
    const result = await res.json();
    if (result.IsSuccessful) {
      return result.Result;
    }
    throw new Error(result.Message.Message);
  } catch (err) {
    throw new Error(err?.message ?? 'Update contract failed');
  }
};

export const deleteContract = async (contractID) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/Contract/DeleteContract/${contractID}`,
      {
        method: 'PUT',
        headers: {
          authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    );
    const result = await res.json();
    if (result.IsSuccessful) {
      return true;
    }
    throw new Error(result.Message.Message);
  } catch (err) {
    throw new Error(err?.message ?? 'Delete contract failed');
  }
};
