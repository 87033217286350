import React, { useState, useEffect } from 'react';
import FormPageTopbar from 'components/Shared/FormPageTopbar';
import SchoolForm from 'components/School/SchoolForm';
import PopupModal from 'components/Shared/PopupModal';
import SuccessModalBody from 'components/Shared/SuccesModalBody';
import { getAccountManagers } from 'services/account-manager';
import { useHistory } from 'react-router-dom';

const UpdateSchool = ({ match, selectedSchool }) => {
  const customerID = parseInt(match.params.id, 10);
  const { ManagerId } = selectedSchool;
  const [AMData, setAMData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const showModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    getAccountManagers().then((res) => {
      setAMData(res.Result.List);
    });
  }, []);

  return (
    <>
      <PopupModal show={modalOpen} hide={closeModal}>
        <SuccessModalBody
          onClick={() => {
            closeModal();
            history.push(`/school/school-list/details/${customerID}`);
          }}
          hide={closeModal}
          type="update-school"
        ></SuccessModalBody>
      </PopupModal>
      <FormPageTopbar
        nav="/school/school-list"
        title="School Info"
      ></FormPageTopbar>
      <SchoolForm
        type="update"
        CustomerId={customerID}
        defaultAccountManager={ManagerId}
        openModal={showModal}
        accountManagers={AMData}
      ></SchoolForm>
    </>
  );
};

export default UpdateSchool;
