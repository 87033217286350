import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { TopbarContainer } from './Topbar.styles';
import NavbarCollapse from './NavbarCollapse';
import NavProfile from './NavProfile';

const Topbar = () => {
  return (
    <TopbarContainer>
      <Link className="navbar-brand" to="/">
        <Logo />
        <span className="admin">Admin Panel</span>
      </Link>
      <NavbarCollapse />
      <NavProfile />
    </TopbarContainer>
  );
};

export default Topbar;
