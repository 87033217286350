import styled from 'styled-components';

export const ContractFormContainer = styled.div`
  padding: 0 50px;
  margin: 30px 0px;
  .date {
    margin-bottom: 10px;
    label {
      font-size: 14px;
      margin-bottom: 0px;
      padding-left: 5px;
    }
  }
  .btn {
    display: flex;
    justify-content: flex-end;
  }
`;

export const DatePickerInput = styled.button.attrs((props) => ({
  type: 'button',
}))`
  background: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
  border: 1px solid #ced4da;
`;
