import React, { useState, useEffect } from 'react';
import Button from 'components/Shared/Button';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

import { FormInput, OptionInput } from 'components/Shared/FormInput';
import Spinner from 'components/Shared/Spinner';
import { useLocation } from 'react-router-dom';
import {
  createAccountManager,
  updateAccountManager,
  getAccountManagerById,
} from 'services/account-manager';
import { countryList } from 'constants/index';
import { AccountManagerFormContainer } from './AMForm.styles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const accountType = ['Reseller', 'Sales Person'];

const AccountManagerForm = ({ type, disable, openModal }) => {
  const location = useLocation();
  const managerId =
    location.state && location.state.managerId ? location.state.managerId : '';
  const [inpState, setInpState] = useState({
    countryid: countryList[0].id,
    resellerid: '',
    name: '',
    companyname: '',
    nric: '',
    phonecountrycode: '',
    mobilenumber: '',
    email: '',
    address: '',
    bankdetails: '',
    nativelanguage: '',
    secondlanguage: '',
    latestmouyear: '',
    latestmou: '',
    salesterritory: '',
    type: accountType[0],
    joindate: '',
    remarks: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [focused, setFocused] = useState(false);

  const bindValues = (val) => {
    const tempState = { ...inpState };
    tempState.countryid = val.countryid;
    tempState.resellerid = val.resellerid;
    tempState.name = val.name;
    tempState.phonecountrycode = val.phonecountrycode;
    tempState.mobilenumber = val.mobilenumber;
    tempState.email = val.email;
    tempState.latestmouyear = val.latestmouyear;
    tempState.latestmou = val.latestmou;

    if (val.NRIC) tempState.nric = val.NRIC;
    if (val.companyname) tempState.companyname = val.companyname;
    if (val.address) tempState.address = val.address;
    if (val.bankdetails) tempState.bankdetails = val.bankdetails;
    if (val.nativelanguage) tempState.nativelanguage = val.nativelanguage;
    if (val.secondlanguage) tempState.secondlanguage = val.secondlanguage;
    if (val.salesterritory) tempState.salesterritory = val.salesterritory;
    if (val.type) {
      const selectedVal = type === '1' ? 'Reseller' : 'Sales Person';
      tempState.type = selectedVal;
    }
    if (val.joindate) tempState.joindate = moment(val.joindate);
    if (val.remarks) tempState.remarks = val.remarks;
    setInpState(tempState);
  };

  useEffect(() => {
    if (managerId) {
      getAccountManagerById(managerId)
        .then((res) => {
          if (res.IsSuccessful) {
            bindValues(res.Result);
          }
        })
        .catch((err) => {
          console.log('err is', err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeState = (val, inpType) => {
    const tempState = { ...inpState };
    tempState[inpType] = val;
    setInpState(tempState);
  };

  const createReqObj = () => {
    const obj = {
      countryid: inpState.countryid,
      resellerid: inpState.resellerid,
      name: inpState.name,
      phonecountrycode: inpState.phonecountrycode,
      mobilenumber: inpState.mobilenumber,
      email: inpState.email,
      latestmouyear: inpState.latestmouyear,
      latestmou: inpState.latestmou,
    };
    if (inpState.nric) obj.nric = inpState.nric;
    if (inpState.companyname) obj.companyname = inpState.companyname;
    if (inpState.address) obj.address = inpState.address;
    if (inpState.bankdetails) obj.bankdetails = inpState.bankdetails;
    if (inpState.nativelanguage) obj.nativelanguage = inpState.nativelanguage;
    if (inpState.secondlanguage) obj.secondlanguage = inpState.secondlanguage;
    if (inpState.salesterritory) obj.salesterritory = inpState.salesterritory;
    if (inpState.type) {
      const selectedVal = type === 'Reseller' ? 1 : 2;
      obj.type = selectedVal;
    }
    if (inpState.joindate) obj.joindate = inpState.joindate;
    if (inpState.remarks) obj.remarks = inpState.remarks;
    return obj;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const rawBody = createReqObj();

    setIsLoading(true);
    const callApi = managerId ? updateAccountManager : createAccountManager;
    callApi(rawBody, managerId)
      .then((res) => {
        if (res.IsSuccessful) {
          openModal();
        }
      })
      .catch((err) => console.log('err is', err));
    setIsLoading(false);
  };
  return (
    <AccountManagerFormContainer>
      {isLoading && <Spinner />}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <OptionInput
            position="col"
            label="Country"
            options={countryList}
            optionKey="name"
            valueKey="id"
            bind="country"
            required
            name="countryid"
            value={inpState.countryid}
            onChange={changeState}
            disabled={disable}
          />
          <OptionInput
            position="col"
            label="Account Type"
            options={accountType}
            bind="account-type"
            name="type"
            value={inpState.type}
            onChange={changeState}
            disabled={disable}
          />
          <FormInput
            position="col"
            label="Company Name"
            type="text"
            value={inpState.companyname}
            name="companyname"
            onChange={changeState}
            disabled={disable}
          />
        </div>
        <div className="row">
          <FormInput
            position="col"
            label="Account Manager ID"
            type="text"
            value={inpState.resellerid}
            required
            name="resellerid"
            onChange={changeState}
            disabled={disable}
          />
          <FormInput
            position="col"
            label="Name"
            type="text"
            required
            value={inpState.name}
            name="name"
            onChange={changeState}
            disabled={disable}
          />
          <FormInput
            position="col"
            label="NRIC"
            type="text"
            value={inpState.nric}
            name="nric"
            onChange={changeState}
            disabled={disable}
          />
        </div>
        <div className="row">
          <FormInput
            position="col"
            label="Country code"
            type="text"
            required
            value={inpState.phonecountrycode}
            name="phonecountrycode"
            onChange={changeState}
            disabled={disable}
          />
          <FormInput
            position="col"
            label="Contact Number"
            type="text"
            required
            value={inpState.mobilenumber}
            name="mobilenumber"
            onChange={changeState}
            disabled={disable}
          />
          <FormInput
            position="col"
            label="Email"
            type="email"
            required
            value={inpState.email}
            name="email"
            onChange={changeState}
            disabled={disable}
          />
        </div>
        <FormInput
          label="Address"
          type="text"
          value={inpState.address}
          name="address"
          onChange={changeState}
          disabled={disable}
        />
        <div className="row">
          <FormInput
            position="col"
            label="Bank Details"
            type="text"
            value={inpState.bankdetails}
            name="bankdetails"
            onChange={changeState}
            disabled={disable}
          />
          <FormInput
            position="col"
            label="Native Language"
            type="text"
            value={inpState.nativelanguage}
            name="nativelanguage"
            onChange={changeState}
            disabled={disable}
          />
          <FormInput
            position="col"
            label="Second Language"
            type="text"
            value={inpState.secondlanguage}
            name="secondlanguage"
            onChange={changeState}
            disabled={disable}
          />
        </div>
        <div className="row">
          <FormInput
            position="col"
            label="Last MOU Signed On"
            type="text"
            required
            value={inpState.latestmouyear}
            name="latestmouyear"
            onChange={changeState}
            disabled={disable}
          />
          <FormInput
            position="col"
            label="Last MOU Doc Link"
            type="text"
            required
            value={inpState.latestmou}
            name="latestmou"
            onChange={changeState}
            disabled={disable}
          />
        </div>
        <div className="row">
          <FormInput
            position="col-5"
            label="Sales Territory"
            type="text"
            value={inpState.salesterritory}
            name="salesterritory"
            onChange={changeState}
            disabled={disable}
          />
          <div className="date">
            <div>
              <label>Join Date</label>
            </div>
            <SingleDatePicker
              date={inpState.joindate}
              onDateChange={(e) => changeState(e, 'joindate')}
              focused={focused}
              onFocusChange={() => setFocused(!focused)}
              disabled={disable}
              displayFormat="DD/MM/YYYY"
            />
          </div>
        </div>
        <FormInput
          label="Remarks"
          type="text"
          value={inpState.remarks}
          name="remarks"
          onChange={changeState}
          disabled={disable}
        />
        <div className="right-btn">
          {type === 'create' && (
            <Button variant="primary-btn" type="submit">
              Create Account
            </Button>
          )}
          {type === 'edit' && disable === false && (
            <Button variant="primary-btn" type="submit">
              Save
            </Button>
          )}
        </div>
      </form>
    </AccountManagerFormContainer>
  );
};

export default AccountManagerForm;
