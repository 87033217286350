import React from 'react';
import SidebarNavLink from './SidebarNavLink';
import SchoolIcon from 'assets/img/sidebar-school.svg';
import SchoolActiveIcon from 'assets/img/sidebar-school-active.svg';
import CreateSchoolIcon from 'assets/img/sidebar-create-school.svg';
import CreateSchoolActiveIcon from 'assets/img/sidebar-create-school-active.svg';

const SidebarSchool = () => {
  return (
    <>
      <SidebarNavLink
        path="/school/school-list"
        icon={SchoolIcon}
        activeIcon={SchoolActiveIcon}
        nav="School List"
      ></SidebarNavLink>
      <SidebarNavLink
        path="/school/create-school"
        icon={CreateSchoolIcon}
        activeIcon={CreateSchoolActiveIcon}
        nav="Create School"
      ></SidebarNavLink>
    </>
  );
};

export default SidebarSchool;
