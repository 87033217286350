import React from 'react';
import Button from 'components/Shared/Button';
import { useHistory } from 'react-router-dom';
import { TopbarContainer } from './FormPageTopbar.styles';

const FormPageTopbar = ({ nav, title, type, onClick }) => {
  const history = useHistory();
  return (
    <TopbarContainer>
      <div className="top-bar">
        <div
          className="back-nav"
          onClick={() => history.goBack()}
        >{`< Back`}</div>
        <div className="title">{title}</div>
        {type === 'account-manager' && (
          <Button className="primary-btn" onClick={onClick}>
            View School List
          </Button>
        )}
      </div>
    </TopbarContainer>
  );
};

export default FormPageTopbar;
