import React, { useState, useEffect } from 'react';
import FormPageTopbar from 'components/Shared/FormPageTopbar';
import ContractForm from 'components/School/ContractForm';
import { getPlans } from 'services/contracts';
import { getAccountManagers } from 'services/account-manager';
import Spinner from 'components/Shared/Spinner';
import { Error } from './CreateContract.styles';

const CreateContract = ({ match, selectedSchool, type }) => {
  const customerID = parseInt(match.params.id, 10);
  const { ManagerId, School_Id, ContractModels } = selectedSchool;

  const [isLoading, setIsLoading] = useState(false);
  // States storing data from API
  const [plans, setPlans] = useState(null);
  const [accountManagers, setAccountManagers] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    getPlans(School_Id)
      .then((res) => {
        setPlans(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError({
          plan: err.message,
        });
      });
  }, [School_Id]);
  useEffect(() => {
    setIsLoading(true);
    setError(null);
    getAccountManagers()
      .then((res) => {
        setAccountManagers(res.Result.List);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError({
          am: err.message,
        });
      });
  }, []);
  return (
    <>
      <FormPageTopbar
        nav={`/school/school-list/details/${customerID}`}
        title={`${type === 'new' ? 'Add' : 'Renew'} Contract for ${
          selectedSchool.SchoolName
        }`}
      />
      {isLoading && <Spinner />}
      {!isLoading && error !== null && (
        <Error>
          {error.plan && <h2>{error.plan}</h2>}
          {error.am && <h2>{error.am}</h2>}
        </Error>
      )}
      {!isLoading && error === null && plans && accountManagers && (
        <ContractForm
          plans={plans}
          accountManagers={accountManagers}
          defaultAccountManager={ManagerId}
          customerID={customerID}
          type={type}
          contracts={ContractModels}
        />
      )}
    </>
  );
};

export default CreateContract;
