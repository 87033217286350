import React, { useState } from 'react';
import Button from 'components/Shared/Button';
import { PopoverMenuItem } from 'components/Shared/ActionButtons';
import FormPageTopbar from 'components/Shared/FormPageTopbar';
import PopupModal from 'components/Shared/PopupModal';
import DeleteModal from 'components/School/DeleteModal';
import AccountManagerForm from 'components/AccountManager/AMForm';
import { useHistory, useLocation } from 'react-router-dom';
import { delAccountManager } from 'services/account-manager';
import SuccessModalBody from 'components/Shared/SuccesModalBody';
import { UpdateAccountManagerContainer } from './UpdateAM.styles';

const UpdateAccountManager = () => {
  const history = useHistory();
  const location = useLocation();

  //succes modal
  const [modalOpen, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  // delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [disable, setDisable] = useState(true);

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteManager = () => {
    delAccountManager(location.state.managerId).then((res) => {
      if (res.IsSuccessful) history.push('/account-manager/list');
    });
  };

  return (
    <UpdateAccountManagerContainer>
      <PopupModal show={showDeleteModal} hide={hideDeleteModal}>
        <DeleteModal
          type="account-manager"
          hide={hideDeleteModal}
          onClick={deleteManager}
        />
      </PopupModal>
      <PopupModal show={modalOpen} hide={closeModal}>
        <SuccessModalBody
          onClick={() => {
            closeModal();
            history.push('/account-manager/list');
          }}
          hide={closeModal}
          type="update-account-manager"
        ></SuccessModalBody>
      </PopupModal>
      <FormPageTopbar
        nav="/account-manager/list"
        title={location.state.manegerName}
        type="account-manager"
        openModal={showModal}
        onClick={() =>
          history.push(
            `/account-manager/account-manager/authorised-school-list/${location.state.managerId}`
          )
        }
      />
      <div className="top-section">
        <div>User Details</div>
        <div className="action-buttons">
          <Button
            className="light-btn"
            // onClick={() => history.push('/school/update-school')}
          >
            <PopoverMenuItem icon="edit">
              <span
                onClick={() => setDisable(false)}
                style={{ marginLeft: '0.5rem' }}
              >
                Edit
              </span>
            </PopoverMenuItem>
          </Button>
          <Button className="light-btn" onClick={() => openDeleteModal()}>
            <PopoverMenuItem icon="delete">
              <span style={{ marginLeft: '0.5rem' }}>Delete</span>
            </PopoverMenuItem>
          </Button>
        </div>
      </div>
      <AccountManagerForm type="edit" disable={disable} openModal={showModal} />
    </UpdateAccountManagerContainer>
  );
};

export default UpdateAccountManager;
