import styled from 'styled-components';

export const PartnerNetworkContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 110vw;
  .background {
    position: absolute;
    height: 100%;
    left: 0px;
    z-index: -1;
  }
  .decoration {
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: -1;
  }
  .title {
    padding: 50px 50px 0px;
    font-family: Linotte Regular;
    h5 {
      font-size: 32px;
      color: white;
    }
    p {
      font-size: 23px;
      color: #ffc551;
    }
  }
  .learn-more {
    background-color: #ffc551;
    color: white;
    max-width: 130px;
    margin-left: 50px;
    &:hover {
      background-color: #feb117;
    }
  }
`;
