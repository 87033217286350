import styled from 'styled-components';

export const NavbarCollapseContainer = styled.div`
  flex-basis: 80%;
  display: flex;
  .navbar-nav {
    display: flex;
    flex-direction: row;
    padding: 0px 60px;
    list-style-type: none;
    margin: 0;
    @media (max-width: 747px) {
      padding: 0px 10px;
    }
  }
`;
