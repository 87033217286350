import styled from 'styled-components';

const DeleteModalContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin: 20px 0px;
    font-weight: 700;
    color: #ff5b00;
    font-size: 18px;
  }
  .subtitle {
    margin: 0px 10px;
    text-align: left;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 20px 0px;
    .no-btn,
    .yes-btn {
      width: 209px;
    }
  }
`;

export default DeleteModalContainer;
