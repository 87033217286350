export const getAccountManagers = async () => {
  return await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/AccountManager/GetAccountManagers`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get account managers failed'); // eslint-disable-line
    });
};

export const getAccountManagerById = async (id) => {
  return await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/AccountManager/GetAccountManagerById/${id}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get account manager by ID failed'); // eslint-disable-line
    });
};

export const getAccountManagerByLoginId = async (loginId) => {
  return await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/AccountManager/FindAccountManager/${loginId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get account manager by login ID failed'); // eslint-disable-line
    });
};

export const delAccountManager = async (userId) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/AccountManager/DeleteAccountManager/${userId}`,
    {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        if (result.IsSuccessful !== undefined) {
          throw new Error(result.Message.Message);
        } else {
          throw new Error(result.message);
        }
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Delete account manager failed'); // eslint-disable-line
    });
};

export const createAccountManager = async (rawBody) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/AccountManager/CreateAccountManager`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(rawBody),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(
        err && err.message ? 'Creating account manager failed' : 'null'
      ); // eslint-disable-line
    });
};

export const updateAccountManager = async (rawBody, id) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/AccountManager/UpdateAccountManager/${id}`,
    {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(rawBody),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(
        err && err.message ? 'Updating account manager failed' : 'null'
      ); // eslint-disable-line
    });
};
