import React from 'react';
import LoginSection from 'components/Login/LoginSection/LoginSection';
import PartnerNetwork from 'components/Login/PartnerNetworkSection/PartnerNetwork';
import { LoginContainer } from './Login.styles';

const Login = () => {
  return (
    <LoginContainer>
      <PartnerNetwork></PartnerNetwork>
      <LoginSection></LoginSection>
    </LoginContainer>
  );
};

export default Login;
