import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormPageTopbar from 'components/Shared/FormPageTopbar';
import PopupModal from 'components/Shared/PopupModal';
import SuccessModalBody from 'components/Shared/SuccesModalBody';
import AccountManagerForm from 'components/AccountManager/AMForm';

const CreateAccountManager = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const showModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <PopupModal show={modalOpen} hide={closeModal}>
        <SuccessModalBody
          onClick={() => {
            closeModal();
            history.push('/account-manager/list');
          }}
          hide={closeModal}
          type="create-account-manager"
        ></SuccessModalBody>
      </PopupModal>
      <FormPageTopbar
        nav="/account-manager/list"
        title="Account Manager Info"
      ></FormPageTopbar>
      <AccountManagerForm
        type="create"
        openModal={showModal}
      ></AccountManagerForm>
    </>
  );
};

export default CreateAccountManager;
