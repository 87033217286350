import React, { useContext } from 'react';
import { PopoverMenu } from 'components/Shared/ActionButtons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { PaymentStatus, Currency } from 'constants/index';
import { SelectedSchoolDispatch } from 'pages/School/School';
import { ContractTableContainer } from './ContractTable.styles';

const ContractTable = ({ data, openModal, schoolData }) => {
  const dispatch = useContext(SelectedSchoolDispatch);
  const history = useHistory();
  return (
    <ContractTableContainer>
      {data === null ? (
        <p className="no-contract">No Contract</p>
      ) : (
        <table className="table-responsive-md table table-bordered">
          <thead>
            <tr>
              <th>No</th>
              <th>Year</th>
              <th>Account Manager</th>
              <th>Value</th>
              <th>No of Accts</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.AcademicYear}</td>
                  <td>{item.AccountManagerName}</td>
                  <td>
                    {Currency[item.Currency]} {item.TotalValue}
                  </td>
                  <td>
                    {PaymentStatus[item.PaymentStatus] === 'Paid' &&
                    item.ContractDetails.length > 0
                      ? item.ContractDetails.reduce(
                          (accumulator, currentValue) => {
                            return accumulator + currentValue.AccountsPurchased;
                          },
                          0
                        )
                      : '-'}
                  </td>
                  <td>{moment(item.StartDate).format('MMM YYYY')}</td>
                  <td>{moment(item.EndDate).format('MMM YYYY')}</td>
                  <td>
                    <PopoverMenu
                      openModal={openModal}
                      onClick={() => {
                        dispatch({
                          type: 'SET_SELECTED_SCHOOL',
                          data: schoolData,
                        });
                        history.push(
                          `/school/school-list/update-contract/${item.ContractId}`
                        );
                      }}
                      deleteId={item.ContractId}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </ContractTableContainer>
  );
};

export default ContractTable;
