import styled from 'styled-components';

export const InputContainer = styled.div`
  margin-bottom: 10px;
  label {
    font-size: 14px;
    margin-bottom: 0px;
    padding-left: 5px;
  }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ff8b33;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 139, 51, 0.25);
  }
`;

export const CheckboxStyle = styled.div`
  align-self: flex-end;
  label {
    margin-left: 5px;
  }
`;
