import React from 'react';
import { Modal } from 'react-bootstrap';
import PopupModal from 'components/Shared/PopupModal';
import Button from 'components/Shared/Button';
import InfoModalStyle from './InfoModal.styles';

export default function InfoModal({ msg, variant, show, hide }) {
  return (
    <PopupModal show={show} hide={hide}>
      <Modal.Body>
        <InfoModalStyle>
          <p className={variant}>{msg}</p>
          <Button className="primary-btn yes-btn" onClick={hide}>
            Close
          </Button>
        </InfoModalStyle>
      </Modal.Body>
    </PopupModal>
  );
}
