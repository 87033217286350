import styled from 'styled-components';

export const ButtonStyle = styled.div`
  button {
    outline: none;
    cursor: pointer;
    border-radius: 2px;
    padding: 5px 15px;
  }
  .primary-btn {
    border: 1px solid #ff5b00;
    background-color: #ff5b00;
    color: white;
    &:hover {
      background-color: #f25008;
    }
  }
  .secondary-btn {
    color: #f25008;
    border: 1px solid #f25008;
    background-color: white;
    &:hover {
      background-color: #fff7f3;
    }
  }
  .light-btn {
    color: #727272;
    border: 1px solid #c7c7c7;
    background-color: white;
    &:hover {
      color: #ff5b00;
    }
  }
`;
