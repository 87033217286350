import styled from 'styled-components';

const SidebarContainer = styled.div`
  position: fixed;
  top: 51px;
  left: 0;
  bottom: 0;
  width: 195px;
  z-index: 40;
  background: #595b5d;
  @media (max-width: 971px) {
    width: 60px;
  }
  @media (max-width: 931px) {
    top: 58px;
  }
`;

export default SidebarContainer;
