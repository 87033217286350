import styled from 'styled-components';

export const SearchResultContainer = styled.div`
  padding: 0px 50px;
  .title {
    font-weight: bold;
    font-size: 18px;
    margin: 10px 0px;
  }
  .table td {
    padding: 5px;
    vertical-align: middle;
    border: 1px solid #ffd98d;
  }
  tbody {
    tr {
      text-align: center;
      background-color: #ffffff;
    }
  }
  .id {
    width: 80px;
  }
  .country {
    width: 200px;
  }
  .action {
    width: 170px;
  }
  .empty-result {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
`;
