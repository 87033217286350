import React from 'react';
import { useHistory } from 'react-router-dom';
import { convertISODate } from 'helpers/getDateFormat';
import { ContractMenu, PopoverMenu } from '../Shared/ActionButtons';
import { AccountManagerListContainer } from './AMListTable.styles';

const AccountManagerListTable = ({ data, openModal }) => {
  const history = useHistory();
  return (
    <AccountManagerListContainer>
      <table className="table-responsive-md table table-bordered ">
        <thead>
          <tr>
            <th scope="col" className="no">
              No
            </th>
            <th scope="col" className="ID">
              ID
            </th>
            <th scope="col" className="name">
              Name
            </th>
            <th scope="col" className="company-name">
              Company Name
            </th>
            <th scope="col" className="country">
              Country
            </th>
            <th scope="col" className="asl">
              ASL
            </th>
            <th scope="col" className="status">
              Status
            </th>
            <th scope="col" className="join-date">
              Join Date
            </th>
            <th scope="col" className="action" />
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.companyname ? item.companyname : '-'}</td>
                <td>{item.countryName}</td>
                <td>{item.Customers.length}</td>
                <td>{item.status ? 'Active' : 'Inactive'}</td>
                <td>{item.joindate ? convertISODate(item.joindate) : '-'}</td>
                <td className="action-btn">
                  <div className="actions">
                    <ContractMenu
                      type="account-manager"
                      onClick={() =>
                        history.push({
                          pathname: '/account-manager/account-manager/edit',
                          state: {
                            managerId: item.id,
                            manegerName: item.name,
                          },
                        })
                      }
                    />
                    <PopoverMenu
                      openModal={openModal}
                      deleteId={item.id}
                      onClick={() =>
                        history.push({
                          pathname: '/account-manager/account-manager/edit',
                          state: {
                            managerId: item.id,
                            manegerName: item.name,
                          },
                        })
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </AccountManagerListContainer>
  );
};

export default AccountManagerListTable;
