import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import Button from 'components/Shared/Button';
import MatchedResult from 'components/Shared/MatchedResult';
import EmptyResult from 'assets/img/empty-search-results.svg';
import Spinner from 'components/Shared/Spinner';
import { getExistingSchool } from 'services/schools';
import { CountryID } from 'constants/index';
import { SearchResultContainer } from './SearchResult.styles';

const SearchResult = ({ searchTerm }) => {
  const history = useHistory();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 2000);

  useEffect(() => {
    setIsLoading(true);
    getExistingSchool(debouncedSearchTerm).then((res) => {
      setIsLoading(false);
      setData(res.Result);
    });
  }, [debouncedSearchTerm]);

  return (
    <SearchResultContainer>
      {isLoading && <Spinner />}
      {!isLoading && (
        <div>
          {data !== null && data.length > 0 ? (
            <>
              <div className="title">Search result</div>
              {data &&
                data.map((item, index) => (
                  <table className="table table-bordered" key={index}>
                    <tbody>
                      <tr>
                        <td className="id">{item.ID}</td>
                        <td className="country">{CountryID[item.CountryID]}</td>
                        <td className="school">
                          <MatchedResult
                            result={item.Name}
                            searchTerm={debouncedSearchTerm}
                          />
                        </td>
                        <td className="action">
                          <Button
                            className="primary-btn"
                            onClick={() => {
                              history.push(`/school/add-school/${item.Name}`);
                            }}
                          >
                            Add School
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ))}
            </>
          ) : (
            <div className="empty-result">
              <img src={EmptyResult} alt="No results found" />
            </div>
          )}
        </div>
      )}
    </SearchResultContainer>
  );
};

export default SearchResult;
