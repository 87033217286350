export const AccessibilityList = [
  {
    id: 1,
    name: 'Inaccessible',
  },
  {
    id: 2,
    name: 'Not Accessible',
  },
  {
    id: 3,
    name: 'Somewhat Accessible',
  },
  {
    id: 4,
    name: 'Easily Accessible',
  },
  {
    id: 5,
    name: 'Most Accessible',
  },
];

export const AccessibilityType = {
  1: 'Inaccessible',
  2: 'Not Accessible',
  3: 'Somewhat Accessible',
  4: 'Easily Accessible',
  5: 'Most Accessible',
};

export const StatusList = [
  {
    id: 0,
    name: 'Not Active',
  },
  {
    id: 1,
    name: 'Active',
  },
];

export const Status = {
  'Not Active': 0,
  Active: 1,
};

export const PaymentStatus = {
  1: 'Paid',
  2: 'Not Paid',
};

export const Currency = {
  0: 'SGD',
  1: 'SGD',
  2: 'USD',
  3: 'PHP',
  4: 'IDR',
  5: 'MMK',
};

export const CurrencyList = [
  {
    id: 1,
    name: 'SGD',
  },
  {
    id: 2,
    name: 'USD',
  },
  {
    id: 3,
    name: 'PHP',
  },
  {
    id: 4,
    name: 'IDR',
  },
  {
    id: 5,
    name: 'MMK',
  },
];

export const countryList = [
  {
    id: 192,
    name: 'Singapore',
  },
  {
    id: 100,
    name: 'Indonesia',
  },
  {
    id: 169,
    name: 'Philippines',
  },
  {
    id: 232,
    name: 'Vietnam',
  },
  {
    id: 129,
    name: 'Malaysia',
  },
  {
    id: 146,
    name: 'Myanmar',
  },
  {
    id: 13,
    name: 'Australia',
  },
];

export const CountryID = {
  192: 'Singapore',
  100: 'Indonesia',
  169: 'Philippines',
  232: 'Vietnam',
  129: 'Malaysia',
  146: 'Myanmar',
  13: 'Australia',
};

export const customerTypeList = [
  {
    id: 1,
    name: 'School',
  },
  {
    id: 2,
    name: 'Tuition Centre',
  },
  {
    id: 3,
    name: 'Individual',
  },
];

export const CustomerTypes = {
  School: 1,
  'Tuition Centre': 2,
  Individual: 3,
};

export const AccountType = {
  1: 'Student',
  2: 'Student Trial',
  3: 'Teacher',
  4: 'Teacher Trial',
  5: 'Parent',
  6: 'School Admin',
  7: 'Teacher Admin',
};
