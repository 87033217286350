import styled from 'styled-components';

export const ProfileContainer = styled.div`
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const NavProfileContainer = styled.div`
  flex-basis: 5%;
  display: flex;
  justify-content: flex-end;
  .drop-down {
    padding: 5px;
  }
  .dropdown-menu {
    background-color: #4a4947;
    top: 17px !important;
  }
  .dropdown-item {
    background-color: #4a4947;
    color: white;
    &:hover {
      color: #ffaf0e;
    }
  }
`;
