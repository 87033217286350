import styled from 'styled-components';

const SuccessModalContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin: 20px 0px;
  }
  .subtitle {
    margin: 0px 10px;
    text-align: left;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 20px 0px;
    .yes-btn {
      width: 209px;
      background-color: #5ed46a;
      border-color: #29c16a;
    }
  }
`;

export default SuccessModalContainer;
