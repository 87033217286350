import styled from 'styled-components';

export const SchoolFormContainer = styled.div`
  padding: 0 50px;
  margin: 30px 0px;
  .btn {
    display: flex;
    justify-content: flex-end;
  }
`;
