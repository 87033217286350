import React from 'react';
import Button from 'components/Shared/Button';
import DeleteModalContainer from './DeleteModal.styles';

const DeleteModal = ({ hide, onClick, type }) => {
  return (
    <DeleteModalContainer>
      <div className="title">
        <h5>
          Delete {type === 'contract' && 'Contract'}
          {type === 'account-manager' && 'Account Manager'}
          {type === 'school' && 'School'}
        </h5>
      </div>
      <div className="subtitle">
        Do you want to delete {type === 'contract' && 'contract'}{' '}
        {type === 'account-manager' && 'account manager'}
        {type === 'school' && 'school'} you selected?
      </div>
      <div className="buttons">
        <Button className="light-btn no-btn" onClick={() => hide()}>
          No
        </Button>
        <Button className="primary-btn yes-btn" onClick={onClick}>
          Yes
        </Button>
      </div>
    </DeleteModalContainer>
  );
};

export default DeleteModal;
