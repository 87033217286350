import React from 'react';
import AccManagerIcon from 'assets/img/sidebar-account-manager.svg';
import AccManagerActiveIcon from 'assets/img/sidebar-account-manager-active.svg';
import CreateAccIcon from 'assets/img/sidebar-create-account.svg';
import CreateAccActiveIcon from 'assets/img/sidebar-create-account-active.svg';
import SidebarNavLink from './SidebarNavLink';

const SidebarSchool = () => {
  return (
    <>
      <SidebarNavLink
        path="/account-manager/list"
        icon={AccManagerIcon}
        activeIcon={AccManagerActiveIcon}
        nav="Account Manager"
      />
      <SidebarNavLink
        path="/account-manager/create-account-manager"
        icon={CreateAccIcon}
        activeIcon={CreateAccActiveIcon}
        nav="Create Account"
      />
    </>
  );
};

export default SidebarSchool;
