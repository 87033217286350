import React from 'react';
import { Modal } from 'react-bootstrap';
import { StyledModal } from './PopupModal.styles';

export default function PopupModal({ children, show, hide }) {
  return (
    <StyledModal
      show={show}
      keyboard={false}
      onHide={() => {
        hide();
      }}
    >
      <Modal.Body>{children}</Modal.Body>
    </StyledModal>
  );
}
