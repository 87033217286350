import React from 'react';
import Background from 'assets/img/Login/login-background.svg';
import { PartnerNetworkContainer } from './PartnerNetwork.styles';

const PartnerNetwork = () => {
  return (
    <PartnerNetworkContainer>
      <img className="background" src={Background} alt="background" />
      <div className="title">
        <h5>KooBits Learning</h5>
        <p>Bring the best of education to your region</p>
      </div>
      <a
        className="btn learn-more"
        href="https://www.koobits.com/"
        role="button"
      >
        Learn More
      </a>
    </PartnerNetworkContainer>
  );
};

export default PartnerNetwork;
